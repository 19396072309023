@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100..900;1,100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

body,
html {
  width: 100%;
  height: 100%;
  font-family: "Roboto", serif !important;
  font-style: normal;
  font-size: 16px;
  line-height: 1.3rem;
}

:root {
  --bg_elem: rgb(53, 60, 77);
  --orng: rgb(240, 112, 0);
  --green: rgb(44 172 64);
  --green8: rgba(44, 172, 63, 0.8);
  --text_slate: rgb(123 127 136);
  --slate_dark: rgb(67, 74, 94);
  --text_grey: rgb(147 150 157);
}

/* utils  */
.row {
  width: 100% !important;
  margin-inline: auto !important;
}
.start-25 {
  left: 25%;
}
.end-25 {
  right: 25%;
}
.bg_themed {
  background: #131722;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--text_slate) !important;
}

a {
  color: var(--orng);
}
button {
  border-radius: 0.175rem;
}

.header_Trading_Room {
  border-bottom: 1px solid rgba(255, 255, 255, 0.25);
}

.main_bg,
.header_Trading_Room {
  background-color: rgb(25 31 45);
}

.text_slate {
  color: var(--text_slate) !important;
}
.text_slatedk {
  color: var(--slate_dark);
}
.text_orng {
  color: var(--orng);
}
.text_green {
  color: var(--green);
}
.text_grey {
  color: var(--text_grey);
}
.fs10 {
  font-size: 10px;
}
.fs11 {
  font-size: 11px;
}
.fs12 {
  font-size: 12px;
}
.fs13 {
  font-size: 13px;
}
.fs14 {
  font-size: 14px;
}
.fs15 {
  font-size: 15px;
}
.fs16 {
  font-size: 16px;
}
.fs24 {
  font-size: 24px;
}
.fs28 {
  font-size: 28px;
}
.fs32 {
  font-size: 32px;
}
.fs36 {
  font-size: 36px;
}
.fs40 {
  font-size: 40px;
}
.fs44 {
  font-size: 44px;
}
.fs48 {
  font-size: 48px;
}
.fs52 {
  font-size: 52px;
}
.fs4r {
  font-size: 4rem;
}
.fs5r {
  font-size: 5rem;
}
.fs6r {
  font-size: 6rem;
}
.curved {
  border-radius: 2px;
}

/* .bgelem {
  background-color: var(--bg_elem) !important;
} */
.bg_slatedk {
  background-color: var(--slate_dark);
}
.btn_big {
  padding: 8px 32px;
}
.bg_green {
  background-color: var(--green);
}
.bg_orng {
  background-color: var(--orng);
}

.bg_orng {
  background-color: var(--orng) !important;
}
.btn.bg_green:hover {
  background-color: var(--green8);
}

.btn_green {
  color: var(--green) !important;
}
.fb_btn {
  background: rgb(58, 85, 156);
}
.fb_btn:hover,
.fb_btn:focus {
  background: rgb(47, 70, 123);
}

.hollow_btn,
.hollow_btn:hover {
  border: 1px solid rgb(225, 225, 225) !important;
  background: transparent;
  color: var(--slate_dark);
}
.hollow-anchor:hover {
  background-color: rgba(0, 0, 0, 0.08);
}

.outline_green {
  border: 1px solid var(--green);
  color: inherit;
}
.outline_green:hover {
  background-color: var(--green);
  color: white !important;
}

.btngrey {
  background-color: var(--slate_dark);
}
.btngrey:hover {
  background-color: rgb(70, 80, 100) !important;
}

/* header outer  */

header.headerouter {
  background: rgb(25, 31, 45);
  min-height: 78px;
}
.header_Trading_Room {
  min-height: 78px;
}
header.headerouter + .container-fluid,
.header_Trading_Room + .container-fluid {
  min-height: calc(100vh - 78px);
}

/* traders room heade  */

.coin.active {
  background-color: var(--bg_elem) !important;
}

.coinslist_wrapper {
  position: absolute;
  left: 0;
  top: 120%;
  min-width: 320px;
  max-width: 450px;
  width: 530px;
  z-index: 9;
}

@media screen and (max-width: 576px) {
  .coinslist_wrapper {
    width: 320px !important;
    left: -10rem !important;
    top: 160% !important;
  }
}

.header_right .usericon {
  color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
}
.header_right .usericon:hover {
  color: rgb(255, 255, 255);
}
.usermenu > ul > li:hover {
  background-color: rgb(53, 60, 77, 0.5);
}
.usermenu > ul > li:hover > a > .listitemicon {
  color: #fff;
}
.usermenu > ul > li:last-child {
  border-top: 1px solid rgba(255, 255, 255, 0.5);
  padding-top: 4px;
}

.withdrawbtn:hover > svg {
  fill: #fff;
}

/* flipper css  */

.flip-container {
  -webkit-perspective: 1000;
}
.flipper {
  transition: 0.6s;
  -webkit-transform-style: preserve-3d;
  position: relative;
}

.flip-main-container .flip-container .flipper,
.flip-container .flipper {
  animation-name: flipanimation;
  animation-duration: 0.5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes flipanimation {
  0% {
    -webkit-transform: rotateX(-75deg);
    transform-origin: top left;
  }
  100% {
    -webkit-transform: rotateX(0deg) !important;
    transform-origin: top left;
  }
}

.floating_btn_icon {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}

.platform_btn {
  background: rgba(0, 0, 0, 0.85);
  display: inline-block;
  width: 100%;
  border-radius: 5px;
  padding: 10px 5px;
}
.platform_btn:hover {
  background: rgba(0, 0, 0, 0.95);
}

/* traders room  */

li.history_li_item {
  border-top: 1px solid rgb(119 119 119 / 30%);
  padding-block: 5px;
}

.errorInput {
  color: red;
  font-size: medium;
  font-family: sans-serif;
}
.select-custom > button {
  border: 1px solid #dee2e6 !important;
  border-radius: 0.375rem !important;
  padding: 1rem !important;
}
.hollow-anchor {
  background: #00000014 !important;
}
.select__control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: var(--bs-body-color);
  appearance: none;
  background-color: var(--bs-body-bg);
  background-clip: padding-box;
  border: var(--bs-border-width) solid var(--bs-border-color);
  border-radius: var(--bs-border-radius);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

/* forgot password  */

.css-1aspc4a {
  max-width: 420px;
  margin: 0px auto;
  padding: 0px 16px;
}
.css-1ffs9d1 {
  font-size: 24px;
  color: rgb(74, 74, 74);
  text-align: center;
  line-height: 32px;
  font-weight: 600;
  margin: 0px 0px 20px;
}
@media screen and (min-width: 480px) {
  .css-1ffs9d1 {
    font-size: 30px;
    line-height: 40px;
  }
}
@media screen and (min-width: 480px) {
  .css-1ffs9d1 {
    font-size: 30px;
    line-height: 40px;
  }
}
.css-1u76601 {
  margin-bottom: 16px;
  background: rgb(255, 255, 255);
}
.css-1b7kpa {
  position: relative;
  border-radius: 4px;
  height: 48px;
  border: 1px solid rgb(225, 225, 225);
  overflow: hidden;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
}
.css-kvxi0i {
  border: none;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  background: transparent;
  font-size: inherit;
  color: inherit;
  font-weight: 500;
  padding: 0px 16px;
}
.css-120qcw7 {
  display: block;
  width: 100%;
  margin-bottom: 16px;
}
.css-xi606m {
  text-align: center;
}
.css-vwowzz {
  padding: 0px;
  color: rgb(255, 87, 34);
  font-size: 12px;
  line-height: 20px;
}
.css-gecade {
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
  text-align: center;
  margin-top: 7px;
}
.link {
  color: #ff5722;
  cursor: pointer;
  text-decoration: none;
}
.Button_green {
  background-color: #35a947;
  color: #fff;
}
.Button_fontBig {
  font-size: 16px;
}
.Button_big {
  line-height: 24px;
  padding: 12px 20px;
}
.Button_simple {
  border: 0;
}
.Button_green:focus,
.Button_green:hover {
  background-color: #2d8f3c;
  color: #fff;
}
.css-v8v0jn {
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  text-align: center;
  margin: 24px 0px;
}
.Button {
  border: 1px solid transparent;
}

/* profile  */

.profilenavitem {
  border-bottom: 1px solid rgb(147 150 157 / 25%);
}
.profilenavitem.active > a {
  color: var(--orng);
}
.profilenavitem > a {
  color: var(--slate_dark);
  font-size: 18px;
}

.photoarea {
  position: relative;
  width: 112px;
  height: 112px;
  margin: 0px auto;
}
.css-ymxu6 {
  margin: 18px auto 0px;
}
.css-r0u99n {
  display: block;
  max-width: 286px;
  width: 100%;
  margin: 0px auto;
  padding: 12px 8px;
  background: transparent;
  border: 1px dashed rgb(161, 176, 186);
  border-radius: 2px;
  color: rgb(157, 173, 183);
  cursor: pointer;
  transition: color 0.3s, border-color 0.3s;
}
.css-108vnzd {
  font-size: 21px;
  vertical-align: middle;
}
.css-hsk5u7 {
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
  margin-left: 7px;
  vertical-align: middle;
}
@media screen and (min-width: 0px) {
  .css-hsk5u7 {
    font-size: 14px;
    line-height: 22px;
  }
}

.css-qy1nd8 {
  width: 100%;
  color: rgb(155, 155, 155);
  max-width: 540px;
  text-align: center;
  margin: 24px auto 0px;
  font-size: 12px;
  line-height: 20px;
  font-weight: 500;
}
@media screen and (min-width: 480px) {
  .css-qy1nd8 {
    font-size: 14px;
    line-height: 22px;
  }
}

.css-1ej6zhf {
  position: relative;
  padding-top: 29px;
  margin-top: 30px;
  border-top: 1px solid rgb(244, 244, 249);
  display: flex;
  align-items: flex-start;
}
.css-y6q4g1 {
  max-width: 448px;
  margin-right: 24px;
}

@media screen and (min-width: 480px) {
  .css-1envvlz {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (min-width: 0px) {
  .css-1envvlz {
    font-size: 16px;
    line-height: 24px;
  }
}
.css-1envvlz {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  margin: 0px;
  color: rgb(74, 74, 74);
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}

.css-jyilpz {
  padding: 0px 4px;
  word-break: break-word;
  background-color: rgba(255, 119, 0, 0.07);
}

.css-191gowv {
  margin: 10px 0px 0px;
  color: rgb(74, 74, 74);
  font-size: 12px;
  font-weight: 500;
  line-height: 20px;
}
@media screen and (min-width: 0px) {
  .css-191gowv {
    font-size: 14px;
    line-height: 22px;
  }
}

.css-1ahhdvi {
  display: flex;
  margin-left: auto;
  white-space: nowrap;
}
.css-xtbxgd {
  position: relative;
  cursor: pointer;
}
.css-xtbxgd .e18325943 {
  pointer-events: none;
}

.css-ujb5j1 {
  position: relative;
  padding-top: 29px;
  margin-top: 30px;
  border-top: 1px solid rgb(244, 244, 249);
}
.css-1envvlz {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  position: relative;
  margin: 0px;
  color: rgb(74, 74, 74);
  font-size: 14px;
  line-height: 22px;
  font-weight: 600;
}
@media screen and (min-width: 0px) {
  .css-1envvlz {
    font-size: 16px;
    line-height: 24px;
  }
}
@media screen and (min-width: 480px) {
  .css-1envvlz {
    font-size: 16px;
    line-height: 24px;
  }
}
.css-173hohc {
  margin-top: 8px;
  padding: 14px;
  box-sizing: border-box;
  background-color: rgb(244, 244, 249);
}
.css-19dfyop {
  display: flex;
  margin: 0px auto;
  color: rgb(155, 155, 155);
  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  max-width: 340px;
  min-height: 162px;
  text-align: center;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
}
@media screen and (min-width: 0px) {
  .css-19dfyop {
    font-size: 16px;
    line-height: 22px;
  }
}
.css-x31wn9 {
  margin-top: 24px;
  font-size: 12px;
  line-height: 20px;
}
.css-eqcq9o {
  margin-top: 14px;
  display: inline-block;
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
  color: rgb(255, 87, 34);
  cursor: pointer;
}
@media screen and (min-width: 0px) {
  .css-eqcq9o {
    font-size: 14px;
    line-height: 24px;
  }
}

/* desposit history  */

.desposit-content {
  /* background: #131722; */
  padding: 4rem 0;
  transition: all 0.3s ease-in-out;
}

.card {
  background-color: #ffffff;
  box-shadow: 0px 0px 16px -4px rgba(0, 0, 0, 0.2);
  border: 0;
}
.qr-code-box {
  border: 1px dashed var(--orng);
  /* background: #24292d; */
  color: var(--orng);
  padding: 5px;
  text-align: center;
}
.table > :not(caption) > * > * {
  background-color: transparent;
  color: var(--text_slate);
  padding: 0.5rem 1rem;
}

.dep_close {
  top: 1.5rem;
  cursor: pointer;
}

.dep_close path {
  fill: var(--bg_elem);
}

.dep_show {
  opacity: 1 !important;
  transition: all 0.3s ease-in-out;
  pointer-events: all;
  min-height: 100%;
}

#margin_accord .accordion-body,
#margin_accord .nav-link.active {
  background-color: #1c2030;
  color: white;
}
#margin_accord .nav-link.active {
  border: none;
  border-top: 1px solid #656565;
  border-radius: 0;
}
#margin_accord #nav-tab {
  border-bottom: 0;
}
#margin_accord button.nav-link {
  border: 1px solid #656565;
  border-bottom: 0;
  border-top-left-radius: 0.15rem;
  border-top-right-radius: 0.15rem;
  color: var(--text_grey);
  font-size: 14px;
  font-weight: 600;
  padding-inline: 3.5rem;
}
@media screen and (max-width: 576px) {
  #margin_accord button.nav-link {
    width: 50%;
  }
}
.tab-content .tab-pane:has(.table) {
  max-width: 100%;
  overflow-x: hidden;
  max-height: 200px;
  overflow-y: scroll;
}
#margin_accord .accordion-body .table > :not(caption) > * > * {
  border-color: var(--text_slate);
}
.briefdets {
  border-block: 1px solid var(--text_grey);
  padding: 5px;
  margin-top: 5rem;
}

.withdrawl_methods .nav-link {
  box-shadow: 0 4px 12px #e1e1e1;
  background: transparent !important;
  color: #4e5260 !important;
  font-weight: 600;
  padding-block: 1rem;
  opacity: 0.5;
  transition: all 0.3s ease-in-out;
}
.withdrawl_methods .nav-link.active {
  opacity: 1;
}
.withdrawl_methods {
  min-width: 240px;
}
.method_img {
  max-width: 25px;
}
img {
  max-width: 100%;
}

.history_result_wrapper {
  border-block: 1px solid rgb(222 222 222);
  min-height: 400px;
}

.form-select:focus {
  border-color: #f0700061;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(240 112 0 / 28%);
}

.settinglist_item {
  border-bottom: 1px solid #7b7f8845;
}

/* offcanvas  */

.position_dropdown li > a {
  border-bottom: 1px solid rgb(222 222 222 / 18%) !important;
}
.position_dropdown .dropdown-menu {
  background-color: #1a202e !important;
}

li.ranking_item {
  border-bottom: 1px solid #434a5ea3;
}

.offcanvas select.form-control {
  background: #373f52;
  color: white;
  border: 0;
}

/* account verification  */

.documentupload .imagearea {
  width: 250px;
  height: 175px;
  border: 1px dashed #f07000;
  margin: auto;
  margin-block: 1.5rem;
}

.acc_verification .nav-pills .nav-link.active {
  background-color: rgb(25 31 45 / 74%) !important;
  opacity: 1;
}
.acc_verification .nav-pills .nav-link {
  opacity: 0.6;
  width: 250px;
  background-color: rgb(25 31 45 / 74%) !important;
}

.btndark {
  background-color: rgb(25 31 45 / 74%) !important;
  color: white;
  transition: all 0.3s ease-in-out;
  border-radius: 0.175rem;
}
.btndark:hover {
  background-color: rgb(25 31 45 / 54%) !important;
  color: white;
}
.verification_continue_btn {
  max-width: 250px;
  width: 100%;
}
.bgdark {
  background-color: rgb(25 31 45 / 74%) !important;
}

/* .bg_orng {
  background: #2c65a9 !important;
} */

.spinner-box {
  min-width: 99vw;
  min-height: 99vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #1d2630;
}

.circle-border {
  width: 150px;
  height: 150px;
  padding: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  background: rgb(63, 249, 220);
  background: linear-gradient(
    0deg,
    rgba(63, 249, 220, 0.1) 33%,
    rgba(63, 249, 220, 1) 100%
  );
  animation: spin 0.8s linear 0s infinite;
}

.circle-core {
  width: 100%;
  height: 100%;
  background-color: #1d2630;
  border-radius: 50%;
}

@keyframes spin {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(359deg);
  }
}

@media screen and (min-width: 1280px) and (max-width: 1400px) {
  .banner-carousel .slide-item .image-layer {
    width: 60% !important;
  }
}

@media screen and (max-width: 1280px) {
  .banner-carousel .slide-item .image-layer {
    width: 70% !important;
  }
}

ul.accordion-box .acc-btn:focus + .acc-content {
  display: block !important;
}

.accordion .acc-btn.active span {
  background: unset !important;
  color: #6e6e6e !important;
}
ul.accordion-box .acc-btn:focus .icon-box + span {
  background: #fe5924 !important;
  color: #fff !important;
}
ul.accordion-box .accordion .acc-btn {
  padding: 22px 60px 21px 110px !important;
}

:root {
  --highcharts-background-color: #191f2d;
  --highcharts-color-1: #9689e6;
}

.highcharts-figure {
  margin: 0;
}

#container {
  min-height: 70vh;
  margin: 0 auto;
}
.highcharts-credits {
  display: none;
}

.highcharts-candlestick-series .highcharts-point {
  stroke-width: 1px;
}

.highcharts-point-down {
  fill: #ff4242;
  stroke: #ff4242 !important;
}

.highcharts-candlestick-series .highcharts-point-down {
  fill: #ff4242;
  stroke: #ff4242;
}

.highcharts-candlestick-series .highcharts-point-up {
  fill: #51af7b;
  stroke: #51af7b;
}

.highcharts-point-up {
  fill: #51af7b;
  stroke: #51af7b !important;
}

.highcharts-crosshair-label text {
  font-size: 1em;
}

.highcharts-grid-line {
  stroke: white;
  opacity: 0.2;
}

.highcharts-crosshair {
  stroke-width: 1px;
  stroke-dasharray: 1;
}

.highcharts-plot-line {
  stroke: white;
  stroke-dasharray: 4;
  stroke-width: 1px;
}

.highcharts-crosshair-custom {
  stroke: white;
  stroke-width: 1px;
  stroke-dasharray: 4;
}

.highcharts-popup > button {
  filter: invert(1);
  background-color: var(--highcharts-neutral-color-80);
}

.highcharts-menu-item-btn {
  filter: invert(1);
}

.highcharts-bindings-wrapper .highcharts-submenu-wrapper,
.highcharts-label-annotation > .highcharts-submenu-wrapper {
  background-color: #ffffff;
}

.highcharts-bindings-wrapper li > span.highcharts-menu-item-btn:hover {
  background-color: var(--highcharts-neutral-color-60);
}

.highcharts-popup select,
.highcharts-popup input {
  background-color: #fff;
  color: var(--highcharts-neutral-color-80);
}
.highcharts-button {
  fill: #f7f7f724;
}

.highcharts-bindings-wrapper
  li.highcharts-active
  > span.highcharts-menu-item-btn {
  background-color: var(--highcharts-neutral-color-40);
}

.highcharts-axis-title {
  visibility: hidden;
}

.highcharts-popup > .highcharts-popup-close:hover,
.highcharts-popup button.highcharts-annotation-edit-button:hover,
.highcharts-popup button.highcharts-annotation-remove-button:hover,
.highcharts-popup > button:hover {
  filter: invert(1);
  background-color: var(--highcharts-neutral-color-100);
}

.highcharts-popup > .highcharts-popup-close,
div.highcharts-popup-bottom-row > button {
  margin-right: 3px;
  background-color: var(--bgelem);
  filter: invert(1);
}

.highcharts-input-search-indicators-label {
  color: var(--text_slate);
}
button.highcharts-indicator-list-item {
  color: var(--text_slate);
}

/* div.highcharts-popup.highcharts-no-tooltip > button.highcharts-tab-item.highcharts-tab-item-active {
    background-color: var(--highcharts-neutral-color-80);
} */
div.highcharts-popup.highcharts-no-tooltip
  > button.highcharts-tab-item.highcharts-tab-item-active {
  background-color: var(--bgelem);
}
div.highcharts-popup-rhs-col > button {
  padding: 5px;
}

div.highcharts-popup.highcharts-no-tooltip
  > button.highcharts-tab-item.highcharts-tab-item-disabled {
  background-color: var(--highcharts-neutral-color-60);
}

button.highcharts-menu-item-btn:hover {
  background-color: var(--highcharts-neutral-color-100);
}

button.highcharts-menu-item-btn-disabled {
  background-color: var(--highcharts-neutral-color-80);
}

button.highcharts-menu-item-btn-active {
  background-color: var(--highcharts-neutral-color-60);
}

.highcharts-bindings-wrapper .highcharts-submenu-wrapper {
  background-color: var(--highcharts-background-color);
}

.highcharts-bindings-wrapper .highcharts-stocktools-toolbar li {
  background-color: #232a39;
}

text {
  fill: white !important;
}
/* contact  */

.contact-section .form-group input[type="text"],
.contact-section .form-group input[type="email"],
.contact-section .form-group textarea {
  height: 62px !important;
  font-size: 14px !important;
  padding: 10px 10px 10px 30px !important;
}

/* .contact-content{
  min-height: 60vh;
  display: flex;
  align-items: center;
  justify-content: center;
} */
:root {
  /* //....... Color ........// */
  --primary-color: #ff3c78;
  --light-black: rgba(0, 0, 0, 0.89);
  --black: #000;
  --white: #fff;
  --grey: #aaa;
}

.contact {
  margin-top: 45px;
}

.form {
  display: flex;
  justify-content: space-between;
  margin: 80px 0;
}

.form .form-txt {
  flex-basis: 48%;
}

.form .form-txt h1 {
  font-weight: 600;
  color: var(--black);
  font-size: 40px;
  letter-spacing: 1.5px;
  margin-bottom: 10px;
  color: var(--light-black);
}

.form .form-txt span {
  color: var(--light-black);
  font-size: 14px;
}

.form .form-txt h3 {
  font-size: 22px;
  font-weight: 600;
  margin: 15px 0;
  color: var(--light-black);
}

.form .form-txt p {
  color: var(--light-black);
  font-size: 14px;
}

.form .form-details {
  flex-basis: 48%;
}

.form .form-details input[type="text"],
.form .form-details input[type="email"] {
  padding: 15px 20px;
  color: var(--black);
  outline: none;
  border: 1px solid var(--grey);
  margin: 20px 0px;
  font-size: 14px;
}

.form .form-details textarea {
  padding: 15px 20px;
  color: var(--black);
  outline: none;
  border: 1px solid var(--grey);
  font-size: 14px;
  resize: none;
  width: 100%;
}

.form .form-details button {
  margin: 11px;
  border: 0;
}

@media (max-width: 500px) {
  .form {
    display: flex;
    flex-direction: column;
  }
  .form .form-details button {
    margin-left: 0;
  }
  .form .form-details input[type="text"],
  .form .form-details input[type="email"],
  .form .form-details textarea {
    width: 100%;
    margin-left: 0;
  }
  .form .form-details input[type="text"] {
    margin-bottom: 0px;
  }
}

@media (min-width: 501px) and (max-width: 768px) {
  .form {
    display: flex;
    flex-direction: column;
  }
  .form .form-details button {
    margin-left: 0;
  }
  .form .form-details input[type="text"],
  .form .form-details input[type="email"],
  .form .form-details textarea {
    width: 100%;
    margin-left: 0;
  }
  .form .form-details input[type="text"] {
    margin-bottom: 0px;
  }
}

.fillup_btn {
  transition: all 0.3s ease-in-out;
}
.fillup_btn > * {
  font-weight: 400 !important;
}
.fillup_btn:hover {
  background: #f07000 !important;
}
.fillup_btn:hover > * {
  color: white !important;
}
.ppI {
  width: 45px;
  border-radius: 50%;
}

.form-control.tradingForm {
  color: var(--bs-body-color);
  background-color: var(--bs-body-bg);
  border-color: none;
  outline: 0;
  box-shadow: none !important;
}

.tradingHistorySelect {
  max-width: 90%;
  margin: auto;
}

select.tradingForm.form-control {
  border-radius: 0.2rem !important;
  background: #3b404f !important;
  font-size: 0.85rem !important;
  font-weight: 400;
}

.tradingHistroy.offcanvas {
  background: rgb(30, 36, 53);
  background: linear-gradient(
    180deg,
    rgba(30, 36, 53, 1) 0%,
    rgba(45, 51, 66, 1) 67%
  ) !important;
}

li.history_li_item:hover {
  background: #404554;
  cursor: pointer;
}

.tradingHistroy {
  scrollbar-color: #404554 transparent;
}

.trandingHistroyOption {
  background-color: #3b404f !important;
  border: none !important;
  padding: 1.5rem !important;
}

.trandingHistroyOption:hover {
  background-color: #343844 !important;
}

.tradingHistorytitle {
  font-size: 0.87rem !important;
  font-weight: 400 !important;
  color: #cbcbcb !important;
  letter-spacing: 0.05rem !important;
}

.colorTrade {
  color: #f3f3f3 !important;
  font-weight: 400 !important;
  font-size: 12px;
}

.betsetter {
  animation-name: betsetterpop;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes betsetterpop {
  0% {
    scale: 0;
  }
  50% {
    scale: 1.1;
  }
  100% {
    scale: 1;
  }
}

.result-card {
  position: absolute;
  top: 10rem;
  left: 10em;
  /* background-color: #0aaf4c;  */
  color: white;
  border-radius: 0.1rem;
  padding: 0.5rem 0.8rem;
  font-weight: bold;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.2);
  border: none;
  display: inline-block;
  z-index: 2;
}

.resultWrapper {
  position: relative;
}

.resultWrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  left: -1.5rem;
  transform: translateY(-50%);
  background-color: #0aaf4c;
  width: 1rem;
  height: 1rem;
  z-index: 1;
  rotate: 45deg;
}

g.low g path {
  fill: rgb(219 73 49) !important;
  stroke: none !important;
  animation-name: betsetterpop;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
g.high g path {
  fill: rgb(10, 175, 76) !important;
  stroke: none !important;
  animation-name: betsetterpop;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes betsetterpop {
  0% {
    scale: 0;
  }
  50% {
    scale: 1.1;
  }
  100% {
    scale: 1;
  }
}

.coinslist_wrapper.bgelem.p-4.scale_in.d-block {
  background: #272d3d;
}
.highcharts-background {
  background-image: url("./images/iq/worldmap.jpg");
  background-size: contain;
}

g.low g path {
  fill: rgb(219 73 49) !important;
  stroke: none !important;
  animation-name: betsetterpop;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}
g.high g path {
  fill: rgb(10, 175, 76) !important;
  stroke: none !important;
  animation-name: betsetterpop;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

.highcharts-root image {
  opacity: 0.5 !important;
}
