/*
  Theme Name: IKO - ICO & Crypto Landing Page Template
  Support: themegenix@gmail.com
  Description: IKO - ICO & Crypto Landing Page Template.
  Version: 1.0
*/

/* CSS Index
============================
01. Variable CSS
02. Header
03. Mobile Menu
04. OffCanvas
05. Banner
06. Breadcrumb
07. Contribution
08. Brand
09. Features
10. Chart
11. RoadMap
12. Team
13. Faq
14. Download
15. Document
16. Blog
17. Pagination
18. Contact
19. Footer
20. Preloader
============================
*/

/*=============================
	1. Google Fonts
===============================*/
@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100;200;300;400;500;600;700&amp;family=Plus+Jakarta+Sans:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&amp;display=swap");

/*=============================
	01. Variable CSS
===============================*/
:root {
  --tg-body-font-family: "Outfit", sans-serif;
  --tg-heading-font-family: "Plus Jakarta Sans", sans-serif;
  --tg-primary-color: #e275ff;
  --tg-secondary-color: #0f101e;
  --tg-body-font-color: #92939e;
  --tg-heading-font-color: #ffffff;
  --tg-paragraph-color: #92939e;
  --tg-body-font-size: 16px;
  --tg-body-font-weight: 400;
  --tg-heading-font-weight: 600;
  --tg-body-line-height: 1.62;
  --tg-heading-line-height: 1.2;
  --tg-blue: #0d6efd;
  --tg-indigo: #6610f2;
  --tg-purple: #6f42c1;
  --tg-pink: #d63384;
  --tg-red: #dc3545;
  --tg-orange: #fd7e14;
  --tg-yellow: #ffc107;
  --tg-green: #010314;
  --tg-teal: #20c997;
  --tg-cyan: #0dcaf0;
  --tg-white: #ffffff;
  --tg-black: #010314;
  --tg-gray: #93b2c8;
  --facebook: #3b5998;
  --twitter: #00acee;
  --linkedin: #1976d2;
  --pinterest: #3b5998;
  --youtube: #c4302b;
  --skype: #00aff0;
  --tg-primary-color: #df86aa;
  --tg-theme-color2: #5729d6;
  --tg-black2: #10121d;
  --tg-secondary-color: #010314;
  --tg-secondary-color2: #0f101e;
  --tg-primary-color: #df86aa;
  --tg-theme-color2: #5729d6;
  --tg-black2: #10121d;
  --tg-secondary-color: #010314;
  --tg-secondary-color2: #0f101e;
}
:root {
  scroll-behavior: auto;
}
body {
  /* .home-purple-gradient { */
  font-family: var(--tg-body-font-family);
  font-weight: var(--tg-body-font-weight);
  font-size: var(--tg-body-font-size);
  line-height: var(--tg-body-line-height);
  color: var(--tg-body-font-color);
  font-style: normal;
  background-color: var(--tg-black);
}
img,
.img {
  max-width: 100%;
  transition: all 0.3s ease-out 0s;
}
.f-left {
  float: left;
}
.f-right {
  float: right;
}
.fix {
  overflow: hidden;
}
a,
button {
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  text-decoration: none;
}
a:focus,
.Home.btn:focus,
button:focus {
  text-decoration: none;
  outline: none;
  box-shadow: none;
}

a:hover,
.portfolio-cat a:hover,
.footer -menu li a:hover {
  color: var(--tg-primary-color);
  text-decoration: none;
}
a,
button {
  color: var(--tg-primary-color);
  outline: medium none;
  text-decoration: none;
}
.Home.btn:focus,
button:focus,
input:focus,
input:focus,
textarea,
textarea:focus {
  outline: 0;
}
.uppercase {
  text-transform: uppercase;
}
.capitalize {
  text-transform: capitalize;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: var(--tg-heading-font-family);
  color: var(--tg-heading-font-color);
  margin-top: 0px;
  margin-bottom: 0.7rem;
  font-style: normal;
  line-height: var(--tg-heading-line-height);
  font-weight: var(--tg-heading-font-weight);
  text-transform: inherit;
}
h1 a,
h2 a,
h3 a,
h4 a,
h5 a,
h6 a {
  color: inherit;
}
h1 {
  font-size: 2.5rem;
}
h2 {
  font-size: 2rem;
}
h3 {
  font-size: 1.75rem;
}
h4 {
  font-size: 1.5rem;
}
h5 {
  font-size: 1.25rem;
}
h6 {
  font-size: 1rem;
}
.list-wrap {
  margin: 0px;
  padding: 0px;
}
.list-wrap li {
  list-style: none;
}
p {
  font-size: var(--tg-body-font-size);
  font-weight: var(--tg-body-font-weight);
  line-height: var(--tg-body-line-height);
  color: var(--tg-paragraph-color);
  margin-bottom: 15px;
}
hr {
  border-bottom: 1px solid var(--tg-primary-color);
  border-top: 0 none;
  margin: 30px 0;
  padding: 0;
}
label {
  color: var(--tg-body-font-color);
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
}
*::-moz-selection {
  background: var(--tg-primary-color);
  color: var(--tg-white);
  text-shadow: none;
}
::-moz-selection {
  background: var(--tg-primary-color);
  color: var(--tg-white);
  text-shadow: none;
}
::selection {
  background: var(--tg-primary-color);
  color: var(--tg-white);
  text-shadow: none;
}
*::-moz-placeholder {
  color: var(--tg-body-font-color);
  font-size: var(--tg-body-font-size);
  opacity: 1;
}
*::placeholder {
  color: var(--tg-body-font-color);
  font-size: var(--tg-body-font-size);
  opacity: 1;
}
::-webkit-scrollbar-track,
::-webkit-scrollbar-track {
  box-shadow: none;
  -webkit-box-shadow: none;
  background-color: var(--tg-secondary-color);
  border-radius: 10px;
}
::-webkit-scrollbar,
::-webkit-scrollbar {
  width: 8px;
  background-color: var(--tg-secondary-color);
}
::-webkit-scrollbar-thumb,
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-image: linear-gradient(
    0deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    var(--tg-black) 99.23%
  );
}
.theme-overlay {
  position: relative;
}
.theme-overlay::before {
  background: var(--tg-primary-color) none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  opacity: 0.6;
  position: absolute;
  top: 0;
  width: 100%;
}
.separator {
  border-top: 1px solid var(--tg-primary-color);
}

/* Bootstrap 5 */
.container {
  padding-left: 15px;
  padding-right: 15px;
}
.row {
  --bs-gutter-x: 30px;
}
.row.g-0 {
  --bs-gutter-x: 0;
}
.gutter-y-30 {
  --bs-gutter-y: 30px;
}

/*=============================
	1. Button style
===============================*/
.Home.btn {
  user-select: none;
  -moz-user-select: none;
  background: var(--tg-white) none repeat scroll 0 0;
  border: medium none;
  border-radius: 30px;
  color: var(--tg-black);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.42px;
  line-height: 1;
  margin-bottom: 0;
  padding: 18px 45px;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  transition: all 0.4s ease;
  vertical-align: middle;
  white-space: nowrap;
}
.Home.btn::after {
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  color: var(--tg-primary-color);
  line-height: 1;
  margin-left: 40px;
  transition: all 0.3s ease 0s;
}
.Home.btn:hover {
  background: var(--tg-secondary-color);
  color: var(--tg-white);
}
.Home.btn:hover::after {
  color: var(--tg-white);
}
.Home.btn.btn-two {
  background: var(--tg-secondary-color);
  color: var(--tg-white);
}
.Home.btn.btn-two::after {
  color: var(--tg-white);
}
.Home.btn.btn-two:hover {
  background: var(--tg-white);
  color: var(--tg-black);
}
.Home.btn.btn-two:hover::after {
  color: var(--tg-primary-color);
}
.breadcrumb > .active {
  color: var(--tg-primary-color);
}

/* scrollUp */
.scroll-top {
  width: 35px;
  height: 35px;
  line-height: 35px;
  position: fixed;
  bottom: -10%;
  right: 30px;
  font-size: var(--tg-body-font-size);
  border-radius: 6px;
  z-index: 99;
  color: var(--tg-white);
  text-align: center;
  cursor: pointer;
  background: var(--tg-primary-color);
  transition: 1s ease;
  border: none;
}
.scroll-top.open {
  bottom: 30px;
}
.scroll-top::after {
  position: absolute;
  z-index: -1;
  content: "";
  top: 100%;
  left: 5%;
  height: 10px;
  width: 90%;
  opacity: 1;
  background: radial-gradient(
    ellipse at center,
    rgba(0, 0, 0, 0.25) 0%,
    rgba(0, 0, 0, 0) 80%
  );
}
.scroll-top:hover {
  background: var(--tg-secondary-color);
}

/*=============================
	02. Header
===============================*/
.custom-container {
  max-width: 1710px;
}
.transparent-header {
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  z-index: 9;
  height: auto;
}
#header-fixed-height.active-height {
  display: block;
  height: 105px;
}
.menu-area {
  padding: 40px 0 20px;
}
.menu-nav {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.navbar-wrap {
  display: flex;
  flex-grow: 1;
}
.navbar-wrap ul {
  display: inline-flex;
  flex-direction: row;
  flex-wrap: wrap;
  padding: 0 70px;
  margin: 0 auto;
  border: 1px solid rgba(255, 255, 255, 0.15);
  background: var(--tg-black);
  border-radius: 40px;
}
.navbar-wrap > ul > li {
  list-style: none;
  display: block;
  position: relative;
  margin-right: 65px;
}
.navbar-wrap > ul > li:last-child {
  margin-right: 0;
}
.navbar-wrap ul li a {
  color: var(--tg-heading-font-color);
  padding: 24px 0;
  display: block;
  line-height: 1;
  position: relative;
  z-index: 1;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.96px;
  text-transform: uppercase;
}
.navbar-wrap > ul > li.active > a,
.navbar-wrap > ul > li:hover > a {
  color: var(--tg-primary-color);
}
.main-menu .navigation li.menu-item-has-children .dropdown-btn {
  display: none;
}
.mobile-menu .navigation .dropdown-btn.open span {
  transform: rotate(180deg);
}
.header-action > ul {
  display: flex;
  align-items: center;
  margin-left: 10px;
}
.header-action > ul li {
  position: relative;
  margin-left: 15px;
}
.header-action ul li:first-child {
  margin-left: 0;
}
.header-action ul li a {
  color: var(--tg-heading-font-color);
  font-size: 14px;
}
.header-action .header-login a {
  font-size: 14px;
  display: flex;
  align-items: center;
  background: var(--tg-white);
  color: var(--tg-black);
  font-weight: 700;
  gap: 12px;
  padding: 14px 23px;
  border-radius: 30px;
}
.header-action .header-login a i {
  color: var(--tg-primary-color);
  transition: all 0.3s ease-out 0s;
}
.header-action .header-login a:hover {
  background: var(--tg-primary-color);
  color: var(--tg-white);
}
.header-action .header-login a:hover i {
  color: var(--tg-white);
}
.offcanvas-menu .menu-tigger {
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-primary-color);
  color: var(--tg-white);
  border-radius: 50%;
  font-size: 16px;
}
.offcanvas-menu .menu-tigger:hover {
  background: var(--tg-white);
  color: var(--tg-primary-color);
}
.navbar-wrap ul li .sub-menu {
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  min-width: 230px;
  border: 1px solid rgba(43 44 56 / 46%);
  background: var(--tg-secondary-color);
  margin: 0 0;
  transform: scale(1, 0);
  transform-origin: 0 0;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-box-shadow: 0px 30px 70px 0px rgba(7 8 8 / 15%);
  -moz-box-shadow: 0px 30px 70px 0px rgba(7 8 8 / 15%);
  box-shadow: 0px 30px 70px 0px rgba(7 8 8 / 15%);
  border-radius: 0;
  padding: 18px 0;
  display: block;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
  border-radius: 0 0 10px 10px;
}
.navbar-wrap ul li .sub-menu .sub-menu {
  right: auto;
  left: 100%;
  top: 0;
}
.navbar-wrap ul li .sub-menu li {
  margin-left: 0;
  text-align: left;
  display: block;
}
.navbar-wrap ul li .sub-menu li a {
  padding: 9px 15px 9px 25px;
  color: var(--tg-white);
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -ms-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  font-weight: 700;
  line-height: normal;
  letter-spacing: 1.96px;
  text-transform: uppercase;
}
.navbar-wrap ul li .sub-menu li.active a,
.navbar-wrap ul li .sub-menu li a:hover {
  color: var(--tg-primary-color);
}
.navbar-wrap ul li:hover > .sub-menu {
  opacity: 1;
  visibility: visible;
  transform: scale(1);
}
.sticky-menu {
  position: fixed;
  left: 0;
  margin: auto;
  top: 0;
  width: 100%;
  z-index: 99;
  background: var(--tg-secondary-color);
  -webkit-animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  animation: 1000ms ease-in-out 0s normal none 1 running fadeInDown;
  -webkit-box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  box-shadow: 0 10px 15px rgba(25, 25, 25, 0.1);
  border-radius: 0;
}
.sticky-menu.menu-area {
  padding: 20px 0;
}

/*=============================
	03. Mobile Menu
===============================*/
.nav-outer .mobile-nav-toggler {
  position: relative;
  float: right;
  font-size: 40px;
  line-height: 50px;
  cursor: pointer;
  display: none;
  color: var(--tg-white);
  margin-right: 30px;
  top: 15px;
}
.nav-logo img {
  max-width: 150px;
  max-height: 38px;
}
.mobile-menu {
  position: fixed;
  right: 0;
  top: 0;
  width: 300px;
  padding-right: 30px;
  max-width: 100%;
  height: 100%;
  z-index: 99;
  border-radius: 0px;
  transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) !important;
  -moz-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -webkit-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  -ms-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86) e;
  -o-transition: all 0.3s cubic-bezier(0.785, 0.135, 0.15, 0.86);
  transform: translateX(0);
}
.mobile-menu .navbar-collapse {
  display: block !important;
}
.mobile-menu .nav-logo {
  position: relative;
  padding: 30px 25px;
  text-align: left;
}
.mobile-menu-visible {
  overflow: hidden;
}
.mobile-menu-visible .mobile-menu {
  -webkit-transform: translateX(0%);
  -ms-transform: translateX(0%);
  transform: translateX(0%);
}
.mobile-menu .navigation li.current > a:before {
  height: 100%;
}
.menu-backdrop {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  transition: all 700ms ease;
  -moz-transition: all 700ms ease;
  -webkit-transition: all 700ms ease;
  -ms-transition: all 700ms ease;
  -o-transition: all 700ms ease;
  opacity: 0;
  visibility: hidden;
  background: rgba(0, 0, 0, 0.5);
}
.mobile-menu-visible .menu-backdrop {
  opacity: 1;
  visibility: visible;
}
.mobile-menu .menu-box {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  max-height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  background: var(--tg-secondary-color);
  padding: 0px 0px;
  z-index: 5;
  box-shadow: -9px 0 14px 0px rgb(0 0 0 / 6%);
}
.mobile-menu-visible .mobile-menu .menu-box {
  opacity: 1;
  visibility: visible;
}
.mobile-menu .close-btn {
  position: absolute;
  right: 15px;
  top: 28px;
  line-height: 30px;
  width: 35px;
  text-align: center;
  font-size: 20px;
  color: var(--tg-primary-color);
  cursor: pointer;
  z-index: 10;
  -webkit-transition: all 0.9s ease;
  -o-transition: all 0.9s ease;
  transition: all 0.9s ease;
}
.mobile-menu-visible .mobile-menu .close-btn {
  -webkit-transform: rotate(360deg);
  -ms-transform: rotate(360deg);
  transform: rotate(360deg);
}
.mobile-menu .navigation {
  position: relative;
  display: block;
  width: 100%;
  float: none;
  margin: 0;
  padding: 0;
}
.mobile-menu .navigation ul {
  padding: 0;
  margin: 0;
}
.mobile-menu .navigation li {
  position: relative;
  display: block;
  border-top: 1px solid rgb(255 255 255 / 10%);
}
.mobile-menu .navigation:last-child {
  border-bottom: 1px solid rgb(255 255 255 / 10%);
}
.mobile-menu .navigation li > ul > li:first-child {
  border-top: 1px solid rgb(255 255 255 / 10%);
}
.mobile-menu .navigation li > a {
  position: relative;
  display: block;
  line-height: 24px;
  padding: 10px 60px 10px 25px;
  font-size: 14px;
  font-weight: 700;
  color: var(--tg-heading-font-color);
  text-transform: uppercase;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: none;
  letter-spacing: 1px;
}
.mobile-menu .navigation li ul li > a {
  font-size: 14px;
  margin-left: 20px;
  text-transform: uppercase;
}
.mobile-menu .navigation li ul li ul li a {
  margin-left: 40px;
}
.mobile-menu .navigation li ul li ul li ul li a {
  margin-left: 60px;
}
.mobile-menu .navigation li > a:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  height: 0;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.mobile-menu .navigation li.menu-item-has-children .dropdown-btn {
  position: absolute;
  right: 15px;
  top: 6px;
  width: 32px;
  height: 32px;
  text-align: center;
  font-size: 16px;
  line-height: 32px;
  color: var(--tg-white);
  background: var(--tg-primary-color);
  cursor: pointer;
  border-radius: 2px;
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  z-index: 5;
}
.mobile-menu .navigation li.menu-item-has-children .dropdown-btn i {
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
}
.mobile-menu .navigation li.menu-item-has-children .dropdown-btn.open i {
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  color: var(--tg-primary-color);
}
.mobile-menu .navigation li > ul,
.mobile-menu .navigation li > ul > li > ul {
  display: none;
}
.mobile-menu .social-links ul {
  display: flex;
  position: relative;
  text-align: center;
  padding: 30px 20px 20px;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
}
.mobile-menu .social-links li {
  position: relative;
  display: inline-block;
  margin: 0px 6px 10px;
}
.mobile-menu .social-links li a {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  position: relative;
  line-height: 32px;
  font-size: 16px;
  color: var(--tg-heading-font-color);
  -webkit-transition: all 500ms ease;
  -o-transition: all 500ms ease;
  transition: all 500ms ease;
  border: 1px solid rgb(255 255 255 / 10%);
  border-radius: 3px;
  -webkit-border-radius: 3px;
  -moz-border-radius: 3px;
  -ms-border-radius: 3px;
  -o-border-radius: 3px;
}
.mobile-menu .social-links li a:hover {
  border-color: var(--tg-primary-color);
  background: var(--tg-primary-color);
  color: var(--tg-white);
}
.menu-area .mobile-nav-toggler {
  position: relative;
  float: right;
  cursor: pointer;
  line-height: 1;
  display: none;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
  background: var(--tg-primary-color);
  font-size: 16px;
  border-radius: 50%;
  color: var(--tg-white);
}

/*=============================
	04. OffCanvas
===============================*/
.extra-info {
  background: var(--tg-black) none repeat scroll 0 0;
  height: 100%;
  padding: 30px;
  position: fixed;
  right: 0;
  top: 0;
  transition: all 0.7s ease 0s;
  width: 340px;
  z-index: 999;
  overflow-y: scroll;
  transform: translateX(100%);
}
.extra-info.active {
  transform: translateX(0);
}
.close-icon {
  margin-top: -16px;
  text-align: right;
}
.close-icon > button {
  background: transparent;
  border: 0 none;
  color: var(--tg-primary-color);
  cursor: pointer;
  font-size: 20px;
  padding: 0;
}
.extra-info .logo-side img {
  max-width: 151px;
}
.side-info {
  border-top: 1px solid var(--tg-primary-color);
  padding-top: 25px;
}
.contact-list h4 {
  color: var(--tg-heading-font-color);
  font-weight: 700;
  font-size: 18px;
}
.contact-list p {
  color: var(--tg-paragraph-color);
  margin: 0;
  margin-bottom: 2px;
  line-height: 26px;
}
.social-icon-right > a {
  color: var(--tg-primary-color);
  display: inline-block;
  margin-right: 20px;
  text-align: center;
}
.social-icon-right > a:hover {
  color: var(--tg-white);
}
.offcanvas-overly {
  position: fixed;
  background: var(--tg-secondary-color);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 99;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
}
.offcanvas-overly.active {
  opacity: 0.7;
  visibility: visible;
}
.extra-info::-webkit-scrollbar {
  width: 0px;
}

/*=============================
	05. Banner
===============================*/
.banner-bg {
  background-size: cover;
  background-position: center;
  border-radius: 0 0 20px 20px;
  position: relative;
  padding: 140px 0px 120px;
  min-height: 100vh;
  display: flex;
  align-items: center;
  margin: 0 20px;
}
.banner-bg::before {
  content: "";
  position: absolute;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    transparent 65.23%
  );
  z-index: -1;
  border-radius: 0px 0px 20px 20px;
}
.banner-content {
  position: relative;
  z-index: 1;
}
.banner-content .title {
  font-size: 65px;
  margin-bottom: 45px;
  letter-spacing: -2.6px;
}
.banner-content .title span {
  background: linear-gradient(
    180deg,
    var(--tg-white) 0%,
    var(--tg-primary-color) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.banner-content p {
  font-size: 20px;
  margin-bottom: 55px;
  color: var(--tg-white);
  font-weight: 500;
}
.coming-time {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 30px 75px;
}
.time-count {
  display: flex;
  align-items: center;
  flex-direction: column;
  background: var(--tg-white);
  border-radius: 5px;
  min-width: 110px;
  color: var(--tg-black);
  font-weight: 500;
  font-size: 14px;
  padding: 15px;
  position: relative;
  line-height: 1.4;
}
.time-count span {
  line-height: 0.9;
  font-size: 35px;
  font-weight: 700;
  color: var(--tg-black);
  font-family: var(--tg-heading-font-family);
  display: block;
  margin-bottom: 5px;
}
.time-count::after,
.time-count::before {
  content: "";
  position: absolute;
  right: -35px;
  top: 50%;
  transform: translateY(-50%);
  width: 7px;
  height: 7px;
  border-radius: 50%;
  background: var(--tg-white);
  opacity: 0.3;
}
.time-count::after {
  right: -45px;
}
.time-count:last-child:after,
.time-count:last-child:before {
  display: none;
}
.banner-scroll-down {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 50px;
}
.banner-scroll-down a {
  color: var(--tg-white);
  display: block;
  width: 30px;
  height: 50px;
  text-align: center;
}
.banner-scroll-down a span {
  display: block;
  width: 15px;
  height: 15px;
  border-bottom: 2px solid var(--tg-white);
  border-right: 2px solid var(--tg-white);
  transform: rotate(45deg);
  margin: -10px 0 0 8px;
  animation: animate 2s infinite;
}
.banner-scroll-down a span:nth-child(2) {
  animation-delay: -0.2s;
}
.banner-scroll-down a span:nth-child(3) {
  animation-delay: -0.4s;
}
.banner-shape-wrap img {
  position: absolute;
}
.banner-shape-wrap img:nth-child(1) {
  left: 107px;
  bottom: 134px;
}
.banner-shape-wrap img:nth-child(2) {
  right: 107px;
  bottom: 120px;
}
@keyframes animate {
  0% {
    opacity: 0;
    transform: rotate(45deg) translate(-15px, -15px);
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
    transform: rotate(45deg) translate(15px, 15px);
  }
}
@keyframes alltuchtopdown {
  0% {
    transform: rotateX(0deg) translateY(0px);
  }
  50% {
    transform: rotateX(0deg) translateY(-30px);
  }
  100% {
    transform: rotateX(0deg) translateY(0px);
  }
}
.alltuchtopdown {
  -webkit-animation-name: alltuchtopdown;
  animation-name: alltuchtopdown;
  -webkit-animation-duration: 6s;
  animation-duration: 6s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes leftToRight {
  0% {
    transform: rotateX(0deg) translateX(0px);
  }
  50% {
    transform: rotateX(0deg) translateX(50px);
  }
  100% {
    transform: rotateX(0deg) translateX(0px);
  }
}
.leftToRight {
  -webkit-animation-name: leftToRight;
  animation-name: leftToRight;
  -webkit-animation-duration: 8s;
  animation-duration: 8s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}

/*=============================
	06. Breadcrumb
===============================*/
.breadcrumb-bg {
  background-size: cover;
  background-position: center;
  border-radius: 0 0 20px 20px;
  position: relative;
  z-index: 1;
  padding: 270px 0 195px;
  margin: 0 20px;
  overflow: hidden;
}
.breadcrumb-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    transparent 65.23%
  );
  z-index: -1;
}
.breadcrumb-content {
  text-align: center;
}
.breadcrumb-content .title {
  font-size: 60px;
  margin-bottom: 25px;
  letter-spacing: -1.8px;
}
.breadcrumb-content .breadcrumb {
  justify-content: center;
  margin-bottom: 0;
}
.breadcrumb-content .breadcrumb-item {
  color: var(--tg-white);
  font-size: 18px;
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}
.breadcrumb-content .breadcrumb-item a {
  color: #b4b4c3;
}
.breadcrumb-content .breadcrumb-item a:hover {
  color: var(--tg-primary-color);
}
.breadcrumb-content .breadcrumb-item + .breadcrumb-item::before {
  padding-right: 10px;
  color: var(--tg-white);
  content: "\f054";
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  font-size: 18px;
  line-height: 1;
  margin-top: 3px;
}
.breadcrumb-content .breadcrumb-item + .breadcrumb-item {
  padding-left: 10px;
}
.breadcrumb-shape-wrap img {
  position: absolute;
  z-index: -1;
}
.breadcrumb-shape-wrap img:nth-child(1) {
  left: 6%;
  top: 42%;
}
.breadcrumb-shape-wrap img:nth-child(2) {
  right: 8%;
  bottom: 22%;
}
.rotateme {
  -webkit-animation-name: rotateme;
  animation-name: rotateme;
  -webkit-animation-duration: 10s;
  animation-duration: 10s;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-timing-function: linear;
  animation-timing-function: linear;
}
@keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-webkit-keyframes rotateme {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

/*=============================
	07. Contribution
===============================*/
.contribution-area {
  position: relative;
}
.contribution-title .title {
  font-size: 60px;
  text-align: center;
  margin-bottom: 0;
  letter-spacing: -1.8px;
}
.contribution-title {
  margin-bottom: 60px;
}
.contribution-title .title span {
  color: var(--tg-primary-color);
}
.progress-wrap .list-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 160px;
  gap: 30px;
}
.progress-wrap .list-wrap li {
  position: relative;
  color: #aaabb2;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  padding-bottom: 40px;
}
.progress-wrap .list-wrap li::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 17px;
  width: 2px;
  height: 14px;
  background: var(--tg-white);
}
.progress-wrap .progress {
  height: 32px;
  background-color: #1c1d34;
  border-radius: 25px;
  padding: 7px 8px;
  margin-bottom: 20px;
}
.progress-wrap .progress-bar {
  border-radius: 25px;
  background: linear-gradient(
    286deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    var(--tg-black) 98.23%
  );
}
.progress-wrap .progress-title {
  margin-bottom: 0;
  font-size: 18px;
  font-weight: 700;
  font-family: var(--tg-body-font-family);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 20px;
}
.contribution-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 25px;
  margin-top: 50px;
  flex-wrap: wrap;
}
.contribution-shape-wrap img {
  position: absolute;
  z-index: -1;
}
.contribution-shape-wrap img:nth-child(1) {
  left: 2%;
  bottom: 25%;
}
.contribution-shape-wrap img:nth-child(2) {
  right: 3%;
  bottom: 23%;
}

/*=============================
	08. Brand
===============================*/
.brand-title {
  margin-bottom: 60px;
}
.brand-title .title {
  display: inline-block;
  position: relative;
  text-transform: uppercase;
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: 1.26px;
  padding: 0 18px;
}
.brand-title .title::after,
.brand-title .title::before {
  content: "";
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background: var(--tg-primary-color);
}
.brand-title .title::after {
  left: auto;
  right: 0;
}
.brand-item-wrap {
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  position: relative;
  padding: 45px 0;
}
.brand-item-wrap::after,
.brand-item-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 18%;
  height: 100%;
  background: linear-gradient(90deg, #010314 0%, rgba(1, 3, 20, 0) 100%);
  z-index: 5;
}
.brand-item-wrap::after {
  left: auto;
  right: 0;
  transform: rotate(180deg);
}
.brand-active .col-12 {
  padding: 0 15px;
}
.brand-item {
  min-height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.brand-item img {
  opacity: 0.5;
  cursor: pointer;
  transition: 0.3s linear;
}
.brand-item img:hover {
  opacity: 1;
}

/*=============================
	09. Features
===============================*/
.section-title .title {
  background: linear-gradient(180deg, var(--tg-white) 0%, var(--tg-gray) 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  font-size: 50px;
  margin-bottom: 0;
  letter-spacing: -1.5px;
}
.features-item {
  display: flex;
  align-items: center;
  background: var(--tg-secondary-color);
  border: 1px solid rgba(43 44 56 / 46%);
  border-radius: 15px;
  padding: 40px 70px 40px 55px;
  margin-bottom: 30px;
}
.features-content {
  width: 50%;
  margin-right: 20px;
}
.features-content .title {
  font-size: 28px;
  font-weight: 600;
  letter-spacing: -0.84px;
  margin-bottom: 25px;
}
.features-img {
  flex-grow: 1;
  text-align: right;
}
.features-content p {
  margin-bottom: 0;
}

/* features-two */
.features-bg {
  background-size: cover;
  background-position: center;
  border-radius: 20px 20px 0 0;
  position: relative;
  margin: 0 20px;
  overflow: hidden;
}
.features-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    var(--tg-black) 65.23%
  );
  z-index: -1;
  transform: rotate(180deg);
}
.features-inner-wrap {
  position: relative;
  padding: 140px 0;
  z-index: 1;
}
.features-line-shape {
  width: 100%;
  height: 2px;
  background: linear-gradient(
    225deg,
    #010314 0%,
    rgba(255, 255, 255, 0.45) 54.98%,
    rgba(1, 3, 20, 0.99) 100%
  );
  position: absolute;
  left: 0;
  bottom: 0;
}
.features-item-wrap {
  margin-bottom: 90px;
}
.features-item-two {
  border-radius: 15px;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.14) 0%,
    rgba(255, 255, 255, 0) 100%
  );
  box-shadow: 0px 16px 18px 0px rgba(1, 12, 50, 0.1);
  display: flex;
  align-items: center;
  padding: 30px 30px;
  margin-bottom: 30px;
  transition: 0.3s linear;
}
.features-item-two:hover {
  transform: translateY(-5px);
}
.features-img-two {
  width: 95px;
  flex: 0 0 auto;
  margin-right: 30px;
}
.features-content-two .title {
  margin-bottom: 0;
  font-size: 24px;
  font-weight: 700;
  letter-spacing: -0.72px;
}
.section-title .title img {
  border-radius: 50px;
  margin: 0 5px;
}
.section-title-two.section-title .title {
  font-size: 60px;
  background: linear-gradient(
    180deg,
    var(--tg-white) 0%,
    var(--tg-primary-color) 100%
  );
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.features-shape-wrap img {
  position: absolute;
}
.features-shape-wrap img:nth-child(1) {
  left: 15%;
  bottom: 6%;
}
.features-shape-wrap img:nth-child(2) {
  right: 9%;
  bottom: 0%;
}

/*=============================
	10. Chart
===============================*/
.chart-inner-wrap {
  position: relative;
}
.chart-inner-wrap::before {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  top: 0;
  width: 2px;
  height: 100%;
  background: linear-gradient(
    225deg,
    var(--tg-black) 0%,
    rgba(255, 255, 255, 0.45) 54.98%,
    rgba(1, 3, 20, 0.99) 100%
  );
}
.chart-wrap {
  margin-left: 30px;
}
.chart-wrap .chart {
  width: 340px;
  height: 340px;
  margin: 0 0 50px 60px;
}
.chart-tab .nav-tabs {
  border-bottom: none;
  display: inline-flex;
  background: #1c1d34;
  border-radius: 30px;
  padding: 10px 10px;
  justify-content: center;
  margin-bottom: 50px;
}
.chart-tab .nav-tabs .nav-link {
  margin-bottom: 0;
  background: transparent;
  border: none;
  font-size: 14px;
  font-weight: 700;
  border-radius: 30px;
  color: var(--tg-white);
  padding: 8px 35px;
}
.chart-tab .nav-tabs .nav-link.active {
  background: var(--tg-white);
  color: #010314;
}
.chart-list .list-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 15px 0;
}
.chart-list .list-wrap li {
  color: var(--tg-white);
  font-size: 16px;
  font-weight: 500;
  letter-spacing: 0.48px;
  width: 50%;
  position: relative;
  padding-left: 30px;
}
.chart-list .list-wrap li::before {
  content: "";
  width: 19px;
  height: 19px;
  border-radius: 50%;
  background: #e275ff;
  position: absolute;
  left: 0;
  top: 3px;
}
.chart-list .list-wrap li:nth-child(2):before {
  background: #8b29a6;
}
.chart-list .list-wrap li:nth-child(3):before {
  background: #621378;
}
.chart-list .list-wrap li:nth-child(4):before {
  background: #4a075c;
}
.chart-list .list-wrap li:nth-child(5):before {
  background: #340142;
}
.chart-list .list-wrap li:nth-child(6):before {
  background: #1c1d34;
}
.chart-inner-wrap .right-side-content {
  margin-left: 190px;
}
.chart-inner-wrap .right-side-content img {
  margin-bottom: 30px;
}
.chart-inner-wrap .right-side-content p {
  margin-bottom: 25px;
  margin-left: 20px;
}
.chart-inner-wrap .right-side-content .list-wrap li {
  display: flex;
  font-size: 18px;
  font-weight: 500;
  color: var(--tg-white);
  align-items: center;
  gap: 10px;
  border-radius: 5px;
  padding: 9px 20px;
  transition: 0.3s ease-in-out;
}
.chart-inner-wrap .right-side-content .list-wrap li span {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgb(255 255 255 / 10%);
  color: var(--tg-white);
  border-radius: 50%;
}
.chart-inner-wrap .right-side-content .list-wrap li:hover {
  background: linear-gradient(
    82deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    var(--tg-black) 98.23%
  );
}
.chart-inner-wrap .right-side-content .list-wrap li:hover span {
  background: var(--tg-black);
}

/*=============================
	11. RoadMap
===============================*/
.roadmap-area {
  overflow: hidden;
}
.roadmap-title {
  display: block;
  font-size: 14px;
  font-weight: 700;
  margin-bottom: 45px;
  margin-left: 14px;
  letter-spacing: 0.56px;
  text-transform: uppercase;
}
.roadmap-content {
  border: 1px solid rgba(43 44 56 / 46%);
  background: var(--tg-secondary-color);
  border-radius: 15px;
  padding: 75px 50px 65px;
}
.roadmap-content .title {
  display: flex;
  align-items: flex-start;
  font-size: 30px;
  font-weight: 500;
  margin-bottom: 32px;
  gap: 16px;
}
.roadmap-content .title .dot {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  display: block;
  position: relative;
  background: rgba(255 255 255 / 11%);
  flex: 0 0 auto;
  transform: translateY(3px);
}
.roadmap-content .title .dot::before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: linear-gradient(
    0deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    var(--tg-black) 98.23%
  );
}
.roadmap-content .title .dot::after {
  content: "";
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 24px;
  width: 1px;
  height: 120px;
  background: #292a37;
}
.roadmap-content p {
  margin-bottom: 0;
}
.roadMap-active .col-lg-4 {
  padding: 0 15px;
}

/*=============================
	12. Team
===============================*/
.team-bg {
  background-size: cover;
  background-position: center;
  border-radius: 0 0 20px 20px;
  position: relative;
  padding: 140px 0;
  overflow: hidden;
  margin: 0 20px;
}
.team-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    var(--tg-black) 91.23%
  );
  z-index: -1;
}
.team-item {
  text-align: center;
}
.team-thumb {
  position: relative;
  margin-bottom: 15px;
}
.team-thumb img {
  mix-blend-mode: luminosity !important;
  display: inline-block !important;
}
.team-thumb .team-social {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  bottom: 30px;
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-white);
  border-radius: 50%;
  font-size: 15px;
}
.team-thumb .team-social:hover {
  background: var(--tg-primary-color);
  color: var(--tg-white);
}
.team-content .title {
  font-size: 22px;
  margin-bottom: 15px;
}
.team-content span {
  line-height: 1;
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #cbd0d4;
}

/*=============================
	13. Faq
===============================*/
.faq-inner-wrap {
  padding: 140px 0;
  position: relative;
}
.faq-inner-wrap::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    225deg,
    #010314 0%,
    rgba(255, 255, 255, 0.45) 54.98%,
    rgba(1, 3, 20, 0.99) 100%
  );
}
.faq-wrap {
  margin: 0 30px;
}
.faq-wrap .accordion-item {
  background-color: var(--tg-secondary-color);
  border: 1px solid rgba(43 44 56 / 46%);
  border-radius: 15px;
  overflow: hidden;
  margin-bottom: 20px;
}
.faq-wrap .accordion-item.active {
  background: linear-gradient(
    0deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    var(--tg-black) 98.23%
  );
  border: none;
}
.faq-wrap .accordion-item:last-child {
  margin-bottom: 0;
}
.faq-wrap .accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: 15px;
  border-bottom-left-radius: 15px;
}
.faq-wrap .accordion-item:first-of-type .accordion-button {
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
}
.faq-wrap .accordion-button {
  padding: 38px 90px 38px 55px;
  font-size: 24px;
  color: var(--tg-white);
  background-color: transparent;
  font-weight: 600;
  letter-spacing: -0.48px;
  font-family: var(--tg-heading-font-family);
  position: relative;
}
.faq-wrap .accordion-button::after {
  flex-shrink: 0;
  width: auto;
  height: auto;
  content: "\f062";
  background-image: none;
  font-size: 25px;
  font-family: "Font Awesome 5 Free";
  font-weight: 700;
  position: absolute;
  right: 45px;
  top: 38px;
  color: var(--tg-white);
  opacity: 0.3;
}
.faq-wrap .accordion-button:not(.collapsed)::after {
  background-image: none;
  opacity: 1;
}
.faq-wrap .accordion-button:not(.collapsed) {
  color: var(--tg-white);
  background-color: transparent;
  box-shadow: none;
}
.faq-wrap .accordion-button:focus {
  border-color: none;
  box-shadow: none;
}
.faq-wrap .accordion-body {
  padding: 0 55px 70px;
}
.faq-wrap .accordion-body p {
  font-size: 18px;
  margin-bottom: 0;
  color: #cbd0d4;
}

/*=============================
	14. Download
===============================*/
.download-inner-wrap {
  background: var(--tg-secondary-color);
  border-radius: 15px;
  border: 1px solid rgba(43 44 56 / 46%);
  padding: 75px 80px 0;
  position: relative;
  z-index: 1;
}
.download-content {
  padding: 40px 0 115px;
}
.download-list .list-wrap {
  display: flex;
  align-items: center;
  gap: 15px 50px;
  flex-wrap: wrap;
  margin-bottom: 50px;
}
.download-list .list-wrap li {
  display: flex;
  align-items: center;
  font-size: 20px;
  font-weight: 500;
  color: var(--tg-white);
  gap: 10px;
}
.download-list .list-wrap li span {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  background: #1a1c2b;
  border-radius: 50%;
  box-shadow: 0px 1px 4px 0px rgba(0, 20, 90, 0.1);
  font-size: 16px;
}
.download-btn-wrap {
  display: flex;
  align-items: center;
  gap: 30px;
  flex-wrap: wrap;
}
.download-btn-wrap .download-btn img {
  max-height: 60px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
}
.download-img {
  position: relative;
  z-index: 1;
  text-align: right;
}
.download-img img:nth-child(2) {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
}
.download-shape-wrap img {
  position: absolute;
  z-index: -1;
}
.download-shape-wrap img:nth-child(1) {
  left: 0;
  bottom: 0;
}
.download-shape-wrap img:nth-child(2) {
  right: 0;
  bottom: 0;
}

/*=============================
	15. Document
===============================*/
.document-area {
  position: relative;
  z-index: 2;
}
.document-inner-wrap .row {
  --bs-gutter-x: 20px;
}
.document-form-wrap {
  background: var(--tg-secondary-color);
  border: 1px solid rgba(43 44 56 / 46%);
  padding: 60px 50px 50px;
  text-align: center;
  border-radius: 15px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.document-form-wrap .title {
  font-size: 28px;
  margin-bottom: 35px;
}
.document-form-wrap .row {
  --bs-gutter-x: 20px;
}
.document-form-wrap .form-grp {
  margin-bottom: 20px;
}
.document-form-wrap .form-grp textarea,
.document-form-wrap .form-grp input {
  width: 100%;
  background: rgba(255 255 255 / 7%);
  border: none;
  border-radius: 30px;
  font-size: 16px;
  color: var(--tg-white);
  padding: 15px 20px;
  line-height: 1;
  height: 50px;
  display: block;
}
.document-form-wrap .form-grp textarea::placeholder,
.document-form-wrap .form-grp input::placeholder {
  font-size: 16px;
  color: #aaabb2;
  line-height: 1;
}
.document-form-wrap .form-grp textarea {
  min-height: 200px;
  max-height: 200px;
}
.document-form-wrap form .btn {
  margin-top: 15px;
}
.document-wrap .btn:hover,
.document-form-wrap form .btn:hover {
  background: var(--tg-primary-color);
}
.document-wrap {
  background: var(--tg-secondary-color);
  border: 1px solid rgba(43 44 56 / 46%);
  padding: 60px 64px 50px;
  border-radius: 15px;
  text-align: center;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.document-wrap .title {
  margin-bottom: 35px;
  font-size: 28px;
}
.document-wrap .list-wrap {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 25px;
}
.document-wrap .list-wrap a {
  font-size: 14px;
  font-weight: 600;
  line-height: 1;
  color: var(--tg-white);
}
.document-wrap .list-wrap a:hover {
  color: var(--tg-primary-color);
}
.document-wrap .list-wrap a .icon {
  width: 130px;
  height: 104px;
  background: rgba(255 255 255 / 7%);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--tg-white);
  font-size: 35px;
  line-height: 0;
  margin-bottom: 5px;
}
.document-shape img {
  position: absolute;
  right: 3%;
  top: -2%;
  z-index: -1;
}

/*=============================
	16. Blog
===============================*/
.blog-masonry-post {
  display: flex;
  background: var(--tg-secondary-color);
  padding: 20px 40px 20px 20px;
  border-radius: 30px;
  border: 1px solid rgba(43 44 56 / 46%);
  margin-bottom: 60px;
}
.blog-masonry-thumb {
  width: 315px;
  flex: 0 0 auto;
  margin-right: 30px;
}
.blog-masonry-thumb a {
  height: 100%;
}
.blog-masonry-thumb img {
  border-radius: 30px;
  mix-blend-mode: luminosity;
  height: 100%;
  object-fit: cover;
}
.blog-meta .list-wrap {
  display: flex;
  align-items: center;
  gap: 15px 50px;
  flex-wrap: wrap;
  margin-bottom: 30px;
}
.blog-meta .list-wrap li {
  display: flex;
  align-items: center;
  font-size: 14px;
}
.blog-meta .list-wrap li i {
  color: var(--tg-white);
  font-size: 16px;
  margin-right: 5px;
}
.blog-meta .list-wrap li a {
  display: flex;
  align-items: center;
  color: var(--tg-paragraph-color);
}
.blog-meta .list-wrap li a:hover {
  color: var(--tg-primary-color);
}
.blog-masonry-content .title {
  margin-bottom: 30px;
  font-size: 28px;
  letter-spacing: -0.84px;
  width: 90%;
}
.blog-masonry-content .title a {
  display: inline;
  background-image: linear-gradient(var(--tg-white), var(--tg-white)),
    linear-gradient(var(--tg-white), var(--tg-white));
  background-size: 0% 1.5px, 0 1.5px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.blog-masonry-content .title a:hover {
  color: var(--tg-white);
  color: inherit;
  background-size: 0 1.5px, 100% 1.5px;
}
.blog-masonry-content p {
  margin-bottom: 55px;
  width: 90%;
}
.blog-masonry-content .content-bottom {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 30px;
}
.blog-author a {
  display: flex;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
  color: var(--tg-white);
}
.blog-author a img {
  border-radius: 50%;
  width: 40px;
  flex: 0 0 auto;
  margin-right: 20px;
}
.blog-author a:hover {
  color: var(--tg-primary-color);
}
.blog-post-wrap .read-more-btn {
  margin-left: auto;
}
.blog-post-wrap .read-more-btn a {
  font-size: 16px;
  color: var(--tg-secondary-color);
  background: var(--tg-white);
  display: inline-flex;
  align-items: center;
  border-radius: 5px;
  gap: 10px;
  padding: 7px 29px;
}
.blog-post-wrap .read-more-btn a i {
  transform: rotate(-45deg);
  transition: all 0.3s ease-out 0s;
}
.blog-post-wrap .read-more-btn a:hover {
  background: var(--tg-primary-color);
  color: var(--tg-white);
}
.blog-post-wrap .read-more-btn a:hover i {
  transform: rotate(0);
}
.blog-standard-post {
  background: var(--tg-secondary-color);
  padding: 20px 20px 60px 20px;
  border-radius: 30px;
  border: 1px solid rgba(43 44 56 / 46%);
  margin-bottom: 60px;
}
.blog-standard-thumb {
  margin-bottom: 30px;
}
.blog-standard-thumb img {
  border-radius: 30px;
  mix-blend-mode: luminosity;
  width: 100%;
}
.blog-standard-content {
  padding: 0 120px 0 40px;
}
.blog-meta .list-wrap .blog-author a {
  font-size: 16px;
  font-weight: 500;
  color: var(--tg-white);
}
.blog-standard-content .title {
  font-size: 28px;
  letter-spacing: -0.84px;
  margin-bottom: 30px;
}
.blog-standard-content .title a {
  display: inline;
  background-image: linear-gradient(var(--tg-white), var(--tg-white)),
    linear-gradient(var(--tg-white), var(--tg-white));
  background-size: 0% 1.5px, 0 1.5px;
  background-position: 100% 100%, 0 100%;
  background-repeat: no-repeat;
  transition: background-size 0.4s linear;
}
.blog-standard-content .title a:hover {
  color: var(--tg-white);
  color: inherit;
  background-size: 0 1.5px, 100% 1.5px;
}
.blog-standard-content p {
  margin-bottom: 55px;
}

/* blog-sidebar */
.blog-widget {
  border-radius: 20px;
  border: 1px solid rgba(43 44 56 / 46%);
  background: var(--tg-secondary-color);
  padding: 45px 40px 50px;
  margin-bottom: 60px;
}
.blog-widget:last-child {
  margin-bottom: 0;
}
.widget-title {
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.72px;
  margin-bottom: 30px;
}
.sidebar-search form {
  position: relative;
}
.sidebar-search form input {
  width: 100%;
  border-radius: 5px;
  border: 1px solid #292a37;
  background: transparent;
  font-size: 18px;
  line-height: 1;
  padding: 18px 55px 18px 10px;
  height: 60px;
  color: var(--tg-white);
}
.sidebar-search form input::placeholder {
  font-size: 18px;
  color: var(--tg-paragraph-color);
}
.sidebar-search form button {
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  background: transparent;
  font-size: 18px;
  padding: 0;
  line-height: 1;
  color: var(--tg-paragraph-color);
}
.sidebar-search form button:hover {
  color: var(--tg-white);
}
.sidebar-cat-list .list-wrap li a {
  font-size: 18px;
  font-weight: 500;
  color: var(--tg-white);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 14px;
  margin-bottom: 14px;
  position: relative;
}
.sidebar-cat-list .list-wrap li a:hover {
  color: var(--tg-paragraph-color);
}
.sidebar-cat-list .list-wrap li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 0;
  height: 1px;
  background: #292a37;
  transition: all 0.3s ease-out 0s;
}
.sidebar-cat-list .list-wrap li a:hover::before {
  width: 100%;
}
.sidebar-cat-list .list-wrap li:last-child a {
  margin: 0;
  padding: 0;
}
.sidebar-cat-list .list-wrap li:last-child a::before {
  display: none;
}
.rc-post-item {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}
.rc-post-item:last-child {
  margin-bottom: 0;
}
.rc-post-item .thumb {
  width: 80px;
  flex: 0 0 auto;
  margin-right: 20px;
}
.rc-post-item .thumb img {
  border-radius: 5px;
  mix-blend-mode: luminosity;
}
.rc-post-item .date {
  display: block;
  font-size: 14px;
  font-weight: 500;
  line-height: normal;
  letter-spacing: 1.4px;
  text-transform: uppercase;
  color: var(--tg-white);
  margin-bottom: 15px;
}
.rc-post-item .title {
  margin-bottom: 0;
  font-size: 18px;
  font-family: var(--tg-body-font-family);
  font-weight: 400;
  color: var(--tg-paragraph-color);
}
.sidebar-follow-wrap .list-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  border-bottom: 1px solid #292a37;
  padding-bottom: 40px;
  margin-bottom: 40px;
}
.sidebar-follow-wrap .list-wrap li a {
  width: 55px;
  height: 55px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #292a37;
  border-radius: 50%;
  color: var(--tg-white);
}
.sidebar-follow-wrap .list-wrap li a:hover {
  background: var(--tg-white);
  border-color: var(--tg-white);
  color: var(--tg-secondary-color);
}
.sidebar-follow-wrap .btn:hover {
  background: var(--tg-primary-color);
}
.sidebar-tag-list .list-wrap {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 10px;
}
.sidebar-tag-list .list-wrap li a {
  color: rgba(146, 147, 158, 0.5);
  font-size: 14px;
  letter-spacing: 0.7px;
  border: 1px solid rgba(43 44 56 / 46%);
  display: block;
  padding: 7px 18px;
  line-height: 1;
  border-radius: 3px;
}
.sidebar-tag-list .list-wrap li a:hover {
  background: var(--tg-white);
  color: var(--tg-secondary-color);
  border-color: var(--tg-white);
}

/* blog-details */
.blog-details-thumb {
  margin-bottom: 30px;
}
.blog-details-thumb img {
  height: 420px;
  object-fit: cover;
  border-radius: 30px;
  mix-blend-mode: luminosity;
}
.blog-details-content {
  margin-bottom: 90px;
}
.blog-details-content .title {
  margin-bottom: 30px;
  font-size: 28px;
}
.blog-details-content > p {
  margin-bottom: 25px;
}
.blog-details-content blockquote {
  background: var(--tg-secondary-color);
  border-radius: 15px;
  border: 1px solid rgba(43 44 56 / 46%);
  padding: 40px 40px;
  margin: 50px 0 50px 0;
}
.blog-details-content blockquote p {
  font-size: 24px;
  font-weight: 600;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-white);
  margin-bottom: 15px;
}
.blog-details-content blockquote cite {
  font-style: normal;
  font-size: 16px;
}
.blog-details-inner-img {
  margin-bottom: 20px;
}
.blog-details-inner-img img {
  border-radius: 30px;
  height: 290px;
  object-fit: cover;
  mix-blend-mode: luminosity;
  margin-bottom: 30px;
}
.blog-details-bottom {
  margin-top: 60px;
  padding-bottom: 60px;
  border-bottom: 1px solid #292a37;
}
.post-tags .list-wrap {
  display: flex;
  align-items: center;
  gap: 10px;
  flex-wrap: wrap;
}
.post-tags .list-wrap li a {
  color: rgba(146, 147, 158, 0.5);
  font-size: 14px;
  letter-spacing: 0.7px;
  border: 1px solid rgba(43 44 56 / 46%);
  display: block;
  padding: 7px 18px;
  line-height: 1;
  border-radius: 3px;
}
.post-tags .list-wrap li a:hover {
  background: var(--tg-white);
  color: var(--tg-secondary-color);
  border-color: var(--tg-white);
}
.blog-post-share .list-wrap {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
}
.blog-post-share .list-wrap li a {
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 2px solid #292a37;
  font-size: 12px;
  color: var(--tg-white);
}
.blog-post-share .list-wrap li a:hover {
  background: var(--tg-white);
  color: var(--tg-secondary-color);
  border-color: var(--tg-white);
}
.blog-avatar-wrap {
  border: 1px solid rgba(43 44 56 / 46%);
  border-radius: 20px;
  display: flex;
  align-items: flex-start;
  padding: 42px 42px;
  background: var(--tg-secondary-color);
}
.blog-avatar-img {
  width: 80px;
  flex: 0 0 auto;
  margin-right: 30px;
}
.blog-avatar-img img {
  border-radius: 50%;
}
.blog-avatar-info .name {
  font-size: 20px;
  margin-bottom: 15px;
}
.blog-avatar-info p {
  margin-bottom: 0;
}
.comments-wrap {
  margin-bottom: 90px;
}
.comments-wrap-title {
  font-size: 28px;
  margin-bottom: 50px;
  letter-spacing: -0.84px;
}
.comments-box {
  display: flex;
  align-items: flex-start;
  margin-bottom: 50px;
}
.comments-avatar {
  width: 70px;
  flex: 0 0 auto;
  margin-right: 30px;
}
.comments-text .avatar-name {
  margin-bottom: 20px;
}
.comments-text .avatar-name .name {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 5px;
  font-size: 18px;
}
.comments-text .avatar-name .name .reply-btn {
  font-size: 12px;
  background: var(--tg-secondary-color);
  border: 1px solid rgba(43 44 56 / 46%);
  display: block;
  color: var(--tg-paragraph-color);
  border-radius: 15px;
  padding: 7px 18px;
}
.comments-text .avatar-name .name .reply-btn:hover {
  background: var(--tg-white);
  color: var(--tg-secondary-color);
  border-color: var(--tg-white);
}
.comments-text .avatar-name .date {
  font-size: 14px;
  display: block;
  line-height: 1;
}
.comments-text p {
  margin-bottom: 0;
}
.latest-comments .children {
  margin: 0 0 0 100px;
}
.comment-respond {
  border: 1px solid rgba(43 44 56 / 46%);
  border-radius: 30px;
  padding: 45px 50px 50px;
  background: var(--tg-secondary-color);
}
.comment-reply-title {
  font-size: 28px;
  margin-bottom: 20px;
  letter-spacing: -0.84px;
}
.comment-notes {
  margin-bottom: 35px;
}
.comment-form .form-grp {
  margin-bottom: 30px;
}
.comment-form .form-grp label {
  display: block;
  font-size: 18px;
  font-weight: 700;
  color: var(--tg-white);
  line-height: 1;
  font-family: var(--tg-heading-font-family);
  margin-bottom: 20px;
}
.comment-form .form-grp textarea,
.comment-form .form-grp input {
  width: 100%;
  border: none;
  border-radius: 30px;
  background: rgba(255 255 255 / 7%);
  padding: 12px 20px;
  font-size: 16px;
  color: var(--tg-white);
  height: 50px;
  display: block;
}
.comment-form .form-grp input::placeholder,
.comment-form .form-grp textarea::placeholder {
  color: #aaabb2;
}
.comment-form .form-grp textarea {
  max-height: 155px;
  min-height: 155px;
}
.comment-form .checkbox-grp {
  display: flex;
  align-items: center;
  margin: 40px 0;
}
.comment-form .checkbox-grp input {
  width: 16px;
  margin-right: 9px;
  height: 16px;
  cursor: pointer;
  background: transparent;
  padding: 0;
  border-radius: 2px;
  border: 1px solid #92939e;
  flex: 0 0 auto;
}
.comment-form .checkbox-grp input:focus {
  box-shadow: none;
}
.comment-form .checkbox-grp label {
  font-weight: 400;
  font-size: 14px;
  color: var(--tg-paragraph-color);
  user-select: none;
  margin-bottom: 0;
}
.comment-form .btn:hover {
  background: var(--tg-primary-color);
}
.comments-avatar img {
  border-radius: 50%;
}

/*=============================
	17. Pagination
===============================*/
.pagination-wrap ul {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 10px 10px;
}
.pagination-wrap ul li a {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 55px;
  height: 55px;
  background: transparent;
  border-radius: 50%;
  font-size: 18px;
  color: var(--tg-white);
  font-weight: 700;
  border: 1px solid rgba(43 44 56 / 46%);
  line-height: 1;
}
.pagination-wrap ul li.active a,
.pagination-wrap ul li a:hover {
  background: var(--tg-secondary-color);
}

/*=============================
	18. Contact
===============================*/
.contact-info-wrap {
  margin-bottom: 110px;
}
.contact-info-item {
  text-align: center;
  background: var(--tg-secondary-color);
  border-radius: 30px;
  margin-bottom: 30px;
  border: 1px solid rgba(43 44 56 / 46%);
  padding: 60px 30px 50px;
}
.contact-info-item .icon {
  width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #292a37;
  margin: 0 auto 20px;
  font-size: 20px;
  color: var(--tg-white);
  line-height: 1;
}
.contact-info-item .content .title {
  font-size: 20px;
  margin-bottom: 20px;
}
.contact-info-item .content p {
  margin-bottom: 0;
  font-size: 20px;
}
.contact-info-item .content a {
  color: var(--tg-paragraph-color);
}
.contact-info-item .content a:hover {
  color: var(--tg-primary-color);
}
.contact-form-wrap .col-43 {
  width: 43%;
  flex: 0 0 auto;
}
.contact-form-wrap .col-57 {
  width: 57%;
  flex: 0 0 auto;
}
.contact-map {
  width: 100%;
  height: 100%;
}
.contact-map iframe {
  width: 100%;
  height: 100%;
  border-radius: 15px 0 0 15px;
}
.contact-form {
  background: var(--tg-secondary-color);
  border-radius: 0 15px 15px 0;
  padding: 50px 50px;
}
.contact-form .title {
  font-size: 28px;
  margin-bottom: 30px;
  font-weight: 500;
}
.contact-form .row {
  --bs-gutter-x: 20px;
}
.contact-form .form-grp {
  margin-bottom: 20px;
}
.contact-form .form-grp textarea,
.contact-form .form-grp input {
  width: 100%;
  background: rgba(255 255 255 / 7%);
  display: block;
  border: none;
  padding: 15px 15px;
  border-radius: 30px;
  color: var(--tg-white);
  line-height: 1;
  height: 50px;
}
.contact-form .form-grp textarea::placeholder,
.contact-form .form-grp input::placeholder {
  color: #aaabb2;
}
.contact-form .form-grp textarea {
  min-height: 160px;
  max-height: 160px;
}
.contact-form .btn:hover {
  background: var(--tg-primary-color);
}
.ajax-response.error {
  color: red;
  margin-top: 20px;
}
.ajax-response.success {
  color: green;
}

/*=============================
	19. Footer
===============================*/
.footer-bg {
  background-size: cover;
  background-position: center;
  border-radius: 20px 20px 0 0;
  position: relative;
  padding: 350px 0 0;
  margin: -350px 20px 0;
  overflow: hidden;
}
.footer-bg::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    0deg,
    var(--tg-primary-color) 0%,
    var(--tg-green) 47.6%,
    var(--tg-black) 65.23%
  );
  z-index: -2;
  transform: rotate(180deg);
}
.footer-top {
  padding: 110px 0 30px;
  z-index: 2;
  position: relative;
}
.footer-widget {
  margin-bottom: 40px;
}
.footer-widget .fw-title {
  margin-bottom: 25px;
  font-size: 18px;
  letter-spacing: 0.9px;
  text-transform: uppercase;
}
.footer-link .list-wrap li {
  margin-bottom: 15px;
}
.footer-link .list-wrap li:last-child {
  margin-bottom: 0;
}
.footer-link .list-wrap li a {
  color: #cbd0d4;
  font-size: 15px;
  font-weight: 500;
  line-height: 1.3;
  position: relative;
}
.footer-link .list-wrap li a::before {
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: 1px;
  background: var(--tg-white);
  -webkit-transform-origin: right top;
  -ms-transform-origin: right top;
  transform-origin: right top;
  -webkit-transform: scale(0, 1);
  -ms-transform: scale(0, 1);
  transform: scale(0, 1);
  -webkit-transition: transform 0.4s cubic-bezier(1, 0, 0, 1);
  transition: transform 0.4s cubic-bezier(0.74, 0.72, 0.27, 0.24);
}
.footer-link .list-wrap li a:hover::before {
  -webkit-transform-origin: left top;
  -ms-transform-origin: left top;
  transform-origin: left top;
  -webkit-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.footer-newsletter p {
  margin-bottom: 60px;
  font-size: 15px;
  color: #cbd0d4;
  font-weight: 500;
}
.footer-newsletter form {
  position: relative;
}
.footer-newsletter form input {
  width: 100%;
  border: none;
  background: var(--tg-secondary-color);
  padding: 24px 80px 24px 18px;
  font-size: 15px;
  color: var(--tg-white);
  font-weight: 400;
  border-radius: 10px;
  height: 72px;
}
.footer-newsletter form input::placeholder {
  font-size: 15px;
  color: #aaabb2;
  font-weight: 400;
}
.footer-newsletter form button {
  width: 65px;
  height: 72px;
  border: none;
  padding: 0;
  background: var(--tg-white);
  position: absolute;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  font-size: 24px;
  color: var(--tg-secondary-color);
}
.footer-newsletter form button:hover {
  color: var(--tg-white);
  background: var(--tg-primary-color);
}
.footer-bottom {
  padding: 30px 0;
  position: relative;
}
.footer-bottom::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 2px;
  background: linear-gradient(
    225deg,
    var(--tg-black) 0%,
    rgba(255, 255, 255, 0.45) 54.98%,
    rgba(1, 3, 20, 0.99) 100%
  );
}
.copyright-text p {
  margin-bottom: 0;
  text-align: center;
  font-size: 14px;
  font-size: 500;
  color: var(--tg-white);
}
.footer-shape-wrap img {
  position: absolute;
}
.footer-shape-wrap img:nth-child(1) {
  left: 0;
  top: 35%;
}
.footer-shape-wrap img:nth-child(2) {
  right: 5%;
  top: 43%;
}

/*=============================
	20. Preloader
===============================*/
#preloader {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  background: var(--tg-black);
  z-index: 999;
  width: 100%;
  height: 100%;
}
.spinner {
  margin: 100px auto;
  width: 50px;
  height: 40px;
  text-align: center;
  font-size: 10px;
}
.spinner > div {
  background-color: var(--tg-primary-color);
  height: 100%;
  width: 6px;
  display: inline-block;
  -webkit-animation: sk-stretchdelay 1.2s infinite ease-in-out;
  animation: sk-stretchdelay 1.2s infinite ease-in-out;
}
.spinner .rect2 {
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.spinner .rect3 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.spinner .rect4 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.spinner .rect5 {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    -webkit-transform: scaleY(0.4);
  }

  20% {
    -webkit-transform: scaleY(1);
  }
}
@keyframes sk-stretchdelay {
  0%,
  40%,
  100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }

  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}

/* Home 2 ---------------------------------- */
.home-purple-gradient {
  font-family: var(--tg-body-font-family);
  font-weight: var(--tg-body-font-weight);
  font-size: var(--tg-body-font-size);
  line-height: var(--tg-body-line-height);
  color: var(--tg-body-font-color);
  font-style: normal;
  background-color: var(--tg-black);
}
.Home .btn5,
.Home .btn2 {
  position: relative;
  z-index: 2;
  overflow: hidden;
  justify-content: center;
}
.Home .btn5:before,
.Home .btn2:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 101%;
  height: 100%;
  border-radius: inherit;
  background: linear-gradient(
    82.3deg,
    var(--tg-primary-color) 10.8%,
    var(--tg-theme-color2) 94.3%
  );
  transition: all 0.475s;
  z-index: -1;
  opacity: 0;
}
.Home .btn5:hover:before,
.Home .btn2:hover:before {
  opacity: 1;
}
.Home .btn3:after {
  display: none;
}
.Home .btn4 {
  background: transparent;
  border: 1px solid var(--tg-white);
  color: var(--tg-white);
}
.Home .btn4:hover {
  background: var(--tg-white);
  color: var(--tg-secondary-color);
}
.Home.btn5:after,
.Home.btn4:after {
  display: none;
}
.Home.btn5 {
  background: linear-gradient(
    93.17deg,
    var(--tg-primary-color) -18.55%,
    #7e2afd 163.09%
  );
  color: var(--tg-white);
  font-weight: 600;
}
.Home.btn5:before {
  background: linear-gradient(
    -93.17deg,
    var(--tg-primary-color) -18.55%,
    #7e2afd 163.09%
  );
}
.Home.btn5:focus,
.Home.btn5:hover,
.Home.btn5.active {
  color: var(--tg-white);
  background: linear-gradient(
    93.17deg,
    var(--tg-primary-color) -18.55%,
    #7e2afd 163.09%
  );
}
.Home.btn5:focus:before,
.Home.btn5:hover:before,
.Home.btn5.active:before {
  opacity: 1;
}
.title.style2 {
  -webkit-text-fill-color: var(--tg-white);
  font-weight: 700;
}
.text-title {
  color: var(--tg-secondary-color) !important;
  -webkit-text-fill-color: var(--tg-secondary-color) !important;
}
.sec-text {
  font-size: 18px;
  margin-top: 30px;
  line-height: 1.667;
  margin-bottom: 0;
}
@media (max-width: 1299px) {
  .sec-text {
    font-size: 16px;
  }
}

.slick-dots {
  list-style-type: none;
  padding: 2px 0 4px;
  margin: 40px 0 0 0;
  line-height: 0;
  text-align: center;
  height: -webkit-max-content;
  height: -moz-max-content;
  height: max-content;
}

.slick-dots li {
  display: inline-block;
  margin-right: 15px;
}

.slick-dots li:last-child {
  margin-right: 0;
}

.slick-dots button {
  font-size: 0;
  padding: 0;
  background-color: rgba(255, 255, 255, 0.3);
  width: 12px;
  height: 12px;
  line-height: 0;
  border-radius: 9999px;
  border: none;
  border: 0;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  position: relative;
}

.slick-dots button:hover {
  border-color: var(--tg-primary-color);
}

.slick-dots button:before {
  content: "";
  position: absolute;
  left: 50%;
  top: 50%;
  width: 18px;
  height: 18px;
  border: 1px solid var(--tg-primary-color);
  background-color: var(--tg-primary-color);
  border-radius: 50%;
  -webkit-transition: all ease 0.4s;
  transition: all ease 0.4s;
  visibility: hidden;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(0);
  -ms-transform: translate(-50%, -50%) scale(0);
  transform: translate(-50%, -50%) scale(0);
}

.slick-dots .slick-active button::before {
  visibility: visible;
  opacity: 1;
  -webkit-transform: translate(-50%, -50%) scale(1);
  -ms-transform: translate(-50%, -50%) scale(1);
  transform: translate(-50%, -50%) scale(1);
}
.icon-box {
  display: inline-flex;
  gap: 20px;
}
.gy-30 {
  --bs-gutter-y: 30px;
}
.gy-40 {
  --bs-gutter-y: 40px;
}
.gy-60 {
  --bs-gutter-y: 60px;
}
/* Bg Gradient ---------------------------------- */
.bg-gradient-1 {
  position: absolute;
  z-index: 0;
  bottom: 0;
  left: 0;
  right: 0;
}
.bg-gradient-1:after {
  content: "";
  position: absolute;
  background: linear-gradient(to top, rgba(46, 32, 255, 0.47), transparent 50%);
  filter: blur(175px);
  inset: 0;
}
.bg-gradient-1 img {
  width: 100%;
}
.bg-gradient-2 {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  right: 0;
}
.bg-gradient-2:after {
  content: "";
  position: absolute;
  background: linear-gradient(
    to top,
    var(--tg-primary-color) 2%,
    rgba(46, 32, 255, 0.67),
    transparent 25%
  );
  filter: blur(175px);
  inset: -100px 0 0 0;
}
@media (min-width: 1922px) {
  .bg-gradient-2:after {
    inset: 0;
  }
}
.bg-gradient-2 img {
  width: 100%;
}

.bg-gradient-3 {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  right: 0;
}
.bg-gradient-3:after {
  content: "";
  position: absolute;
  background: linear-gradient(
    to bottom,
    var(--tg-primary-color) -5.52%,
    rgba(46, 32, 255, 0.27) 56.38%,
    var(--tg-secondary-color) 207.11%
  );
  filter: blur(175px);
  inset: 100px 0 0;
}

@media (min-width: 1922px) {
  .bg-gradient-3:after {
    inset: 0;
  }
}
.bg-gradient-3 img {
  width: 100%;
}

.bg-gradient-4 {
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  right: 0;
}
.bg-gradient-4:after {
  content: "";
  position: absolute;
  background: linear-gradient(
    to top,
    var(--tg-primary-color) -5.52%,
    rgba(46, 32, 255, 0.27) 46.38%,
    var(--tg-secondary-color) 217.11%
  );
  filter: blur(175px);
  inset: 500px 0 0;
}

@media (min-width: 1922px) {
  .bg-gradient-4:after {
    inset: 0;
  }
}
@media (max-width: 1500px) {
  .bg-gradient-4:after {
    inset: 0;
  }
}
@media (max-width: 525px) {
  .bg-gradient-4 {
    top: 30%;
  }
}
.bg-gradient-4 img {
  width: 100%;
}

.bg-gradient-5 {
  position: absolute;
  z-index: -1;
  inset: 0;
  background: linear-gradient(180deg, #7136b8 0%, rgba(16, 11, 33, 0.3) 100%);
}

.bg-black2 {
  background: var(--tg-black2);
}
.z-index-common {
  position: relative;
  z-index: 3;
}
.social-btn {
  display: flex;
  gap: 12px;
}
.social-btn a {
  height: var(--icon-size, 48px);
  width: var(--icon-size, 48px);
  line-height: 48px;
  display: inline-block;
  color: var(--tg-white);
  text-align: center;
  font-size: 16px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.08);
}
.social-btn a:hover {
  background: var(--tg-primary-color);
  color: var(--tg-white);
  border-color: var(--tg-primary-color);
}
/* Header 1 ---------------------------------- */
.header-layout1 .custom-container {
  max-width: 1692px;
}
.header-layout1 .navbar-wrap ul {
  background: transparent;
  border: 0;
  border-radius: 0;
  padding: 0;
}
.header-layout1 .menu-area {
  padding: 23px 0;
}
.header-layout1 .header-action > ul li {
  margin-left: 50px;
}
.header-layout1 .header-action ul li:first-child {
  margin-left: 0;
}
.header-layout1 .header-action .header-login .btn2 {
  padding: 14px 40px;
}
.dropdown-link {
  position: relative;
  display: inline-block;
}
.dropdown-link > a {
  color: var(--tg-white);
  font-size: 14px;
  font-weight: 600;
  font-family: var(--tg-body-font-color);
  letter-spacing: -0.42px;
}
.dropdown-link > a i {
  margin-right: 3px;
  transition: 0.4s;
}
.dropdown-link > a:hover i {
  color: var(--tg-white) !important;
}

.dropdown-toggle::after {
  content: "\f107";
  border: none;
  font-family: "Font Awesome 5 Free";
  vertical-align: middle;
  font-weight: 700;
  margin-left: 6px;
}

.dropdown-menu li {
  padding-right: 0;
  margin-right: 0;
}
.dropdown-menu li:after {
  display: none;
}
.dropdown-menu li a {
  display: block;
}

/* Hero 1 ---------------------------------- */
.hero-1 {
  position: relative;
  overflow: hidden;
  padding-bottom: 145px;
}
.hero-1 .hero-bg-gradient {
  position: absolute;
  left: 50%;
  z-index: 0;
  background: linear-gradient(
    0.41deg,
    var(--tg-primary-color) 19.48%,
    rgba(46, 32, 255, 0.27) -13.62%,
    var(--tg-black) 267.11%
  );
  filter: blur(225px);
  -webkit-filter: blur(225px);
  top: -1292px;
  width: 1873px;
  height: 2191px;
  transform: translate(-50%, 0);
  border-radius: 0 0 50% 50%;
}

@media (max-width: 1199px) {
  .hero-1 .hero-bg-gradient {
    top: -1400px;
  }
  .hero-1 {
    padding-bottom: 120px;
  }
}
@media (max-width: 991px) {
  .hero-1 {
    padding-bottom: 100px;
  }
}
.hero-1 .ripple-shape {
  transform: skewX(240deg);
  position: relative;
  z-index: 1;
  opacity: 0.3;
}
.hero-1 .ripple-1,
.hero-1 .ripple-2,
.hero-1 .ripple-3,
.hero-1 .ripple-4,
.hero-1 .ripple-5,
.hero-1 .ripple-6,
.hero-1 .ripple-7 {
  width: 700px;
  height: 700px;
  position: absolute;
  left: 30%;
  top: 0;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 1px solid var(--tg-white);
  border-radius: 50%;
  animation: ripple2 10s linear infinite;
  opacity: 0;
  transition: 0.4s;
}
.hero-1 .ripple-1 {
  animation-delay: 0;
}
.hero-1 .ripple-2 {
  animation-delay: 1s;
}
.hero-1 .ripple-3 {
  animation-delay: 3s;
}
.hero-1 .ripple-4 {
  animation-delay: 4s;
}
.hero-1 .ripple-4 {
  animation-delay: 5s;
}
.hero-1 .ripple-5 {
  animation-delay: 6s;
}
.hero-1 .ripple-6 {
  animation-delay: 7s;
}
.hero-1 .ripple-7 {
  animation-delay: 8s;
}
.hero-1 .hero-thumb {
  margin: 0 -47px;
}
@keyframes ripple {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  30% {
    opacity: 1;
  }

  100% {
    transform: scale(2.1);
    opacity: 0;
  }
}
@keyframes ripple2 {
  0% {
    transform: scale(0);
    opacity: 1;
  }
  10% {
    opacity: 0.6;
  }
  30% {
    opacity: 0.4;
  }
  40% {
    opacity: 0.2;
  }
  50% {
    opacity: 0.14;
  }
  100% {
    transform: scale(3.5);
    opacity: 0;
  }
}
@media (max-width: 991px) {
  .hero-1 .hero-thumb {
    margin: 0;
  }
}
@media (max-width: 767px) {
  .hero-1 .hero-thumb img {
    transform: scale(0.8);
  }
}
.hero-title {
  margin-bottom: 0;
  color: var(--tg-white) !important;
  font-weight: 700;
  letter-spacing: -2.8px;
  font-size: 70px;
  line-height: 1.2857;
  position: relative;
}
@media (max-width: 1399px) {
  .hero-title {
    font-size: 60px;
  }
}
@media (max-width: 1299px) {
  .hero-title {
    font-size: 50px;
    letter-spacing: normal;
  }
}
@media (max-width: 991px) {
  .hero-title {
    font-size: 40px;
  }
}
@media (max-width: 767px) {
  .hero-title {
    font-size: 36px;
    letter-spacing: normal;
  }
}
@media (max-width: 375px) {
  .hero-title {
    font-size: 34px;
  }
}
.hero-style1 {
  position: relative;
  z-index: 6;
  padding: 220px 0 30px;
}
.hero-style1 .btn-wrap {
  margin-top: 43px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 15px 15px;
}
@media (max-width: 991px) {
  .hero-style1 {
    padding: 170px 0 100px;
    text-align: center;
  }
  .hero-style1 .btn-wrap {
    justify-content: center;
  }
}
@media (max-width: 767px) {
  .hero-style1 {
    padding: 150px 0 100px;
  }
}
@media (max-width: 575px) {
  .hero-style1 {
    padding: 150px 0 80px;
  }
}
.hero-countdown-wrap {
  background: var(--tg-black2) !important;
  border-radius: 16px;
  text-align: center;
  padding: 80px 90px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  position: relative;
}
.hero-countdown-wrap .banner-countdown-wrap {
  margin-top: 70px;
}
.hero-countdown-wrap .banner-countdown-wrap .time-count {
  display: flex;
  align-items: center;
  flex-direction: column-reverse;
  background: var(--tg-secondary-color);
  border-radius: 10px;
  min-width: 110px;
  color: var(--tg-paragraph-color);
  font-weight: 500;
  font-size: 14px;
  padding: 15px 15px 20px;
  position: relative;
  line-height: 1.4;
}
.hero-countdown-wrap .banner-countdown-wrap .time-count span {
  line-height: 0.9;
  font-size: 35px;
  font-weight: 700;
  color: var(--tg-white);
  font-family: var(--tg-heading-font-family);
  display: block;
  margin-top: 7px;
  margin-bottom: 0;
}
@media (max-width: 991px) {
  .hero-countdown-wrap {
    padding: 60px 50px;
  }
  .hero-countdown-wrap .banner-countdown-wrap .coming-time {
    gap: 30px 49px;
  }
  .hero-countdown-wrap .banner-countdown-wrap .coming-time .time-count:before,
  .hero-countdown-wrap .banner-countdown-wrap .coming-time .time-count:after {
    display: none;
  }
}
@media (max-width: 767px) {
  .hero-countdown-wrap {
    padding: 40px 30px;
  }
  .hero-countdown-wrap .banner-countdown-wrap .coming-time {
    gap: 30px 60px;
  }
}
@media (max-width: 375px) {
  .hero-countdown-wrap {
    padding: 40px 20px;
  }
}
.hero-countdown-wrap .hero-countdown-wrap-title {
  color: var(--tg-white) !important;
  font-size: 40px;
  font-weight: 600;
  margin-bottom: 0;
  margin-top: -0.25em;
  position: relative;
}
@media (max-width: 991px) {
  .hero-countdown-wrap .hero-countdown-wrap-title {
    font-size: 30px;
  }
}
@media (max-width: 767px) {
  .hero-countdown-wrap .hero-countdown-wrap-title {
    font-size: 24px;
  }
}
.hero-countdown-wrap .skill-feature_list {
  list-style: none;
  padding: 0;
  margin: 37px 0 25px;
  display: flex;
  justify-content: space-between;
  align-items: end;
}
.hero-countdown-wrap .skill-feature_list li {
  color: var(--tg-white);
  text-align: center;
  position: relative;
}
@media (max-width: 767px) {
  .hero-countdown-wrap .skill-feature_list li {
    font-size: 14px;
  }
}
.hero-countdown-wrap .skill-feature_list li:after {
  content: "";
  position: absolute;
  width: 22px;
  height: 19px;
  border-bottom: solid 19px var(--tg-secondary-color);
  border-left: solid 11px transparent;
  border-right: solid 11px transparent;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 26px);
}
@media (max-width: 575px) {
  .hero-countdown-wrap .skill-feature_list li:after {
    display: none;
  }
}
.hero-countdown-wrap .skill-feature_list li:first-child {
  width: 245px;
  padding-left: 10px;
}
@media (max-width: 1199px) {
  .hero-countdown-wrap .skill-feature_list li:first-child {
    width: 190px;
  }
}
@media (max-width: 991px) {
  .hero-countdown-wrap .skill-feature_list li:first-child {
    width: 170px;
    padding-left: 0;
  }
}
@media (max-width: 767px) {
  .hero-countdown-wrap .skill-feature_list li:first-child {
    width: 140px;
  }
}
@media (max-width: 575px) {
  .hero-countdown-wrap .skill-feature_list li:first-child {
    width: 100px;
  }
}
.hero-countdown-wrap .skill-feature_list li:nth-child(2) {
  width: 240px;
  text-align: center;
}
@media (max-width: 1199px) {
  .hero-countdown-wrap .skill-feature_list li:nth-child(2) {
    width: 200px;
  }
}
@media (max-width: 991px) {
  .hero-countdown-wrap .skill-feature_list li:nth-child(2) {
    width: 140px;
  }
}
@media (max-width: 575px) {
  .hero-countdown-wrap .skill-feature_list li:nth-child(2) {
    width: 100px;
  }
}
.hero-countdown-wrap .skill-feature_list li:last-child {
  margin: 0 auto;
}
.hero-countdown-wrap .skill-feature_list li span {
  display: block;
}
.hero-countdown-wrap .skill-feature_list li h4 {
  color: var(--tg-white);
  margin-bottom: 0;
}
.hero-countdown-wrap .skill-feature_list.style2 {
  margin-top: 17px;
  align-items: start;
}
.hero-countdown-wrap .skill-feature_list.style2 li:first-child {
  padding-left: 0;
  text-align: center;
}
.hero-countdown-wrap .skill-feature_list.style2 li:after {
  display: none;
}
.hero-countdown-wrap .skill-feature {
  background: var(--tg-secondary-color);
  border-radius: 8px;
  padding: 43px 36px 43px 24px;
  display: flex;
  gap: 27px;
  position: relative;
  z-index: 1;
  margin-bottom: 0 !important;
}
@media (max-width: 767px) {
  .hero-countdown-wrap .skill-feature {
    padding: 40px 20px;
  }
}
@media (max-width: 575px) {
  .hero-countdown-wrap .skill-feature {
    background: transparent;
    padding: 0;
  }
}
.hero-countdown-wrap .skill-feature:after,
.hero-countdown-wrap .skill-feature:before {
  content: "";
  position: absolute;
  height: 100%;
  width: 0.5px;
  background: #1c1d34;
  top: 0;
  left: 22%;
  z-index: -1;
  opacity: 0.5;
}
@media (max-width: 1299px) {
  .hero-countdown-wrap .skill-feature:after,
  .hero-countdown-wrap .skill-feature:before {
    left: 26%;
  }
}
@media (max-width: 1199px) {
  .hero-countdown-wrap .skill-feature:after,
  .hero-countdown-wrap .skill-feature:before {
    left: 25%;
  }
}
@media (max-width: 991px) {
  .hero-countdown-wrap .skill-feature:after,
  .hero-countdown-wrap .skill-feature:before {
    left: 28%;
  }
}
@media (max-width: 767px) {
  .hero-countdown-wrap .skill-feature:after,
  .hero-countdown-wrap .skill-feature:before {
    left: 31%;
  }
}
@media (max-width: 575px) {
  .hero-countdown-wrap .skill-feature:after,
  .hero-countdown-wrap .skill-feature:before {
    display: none;
  }
}
.hero-countdown-wrap .skill-feature:after {
  left: 43.5%;
}
@media (max-width: 1299px) {
  .hero-countdown-wrap .skill-feature:after {
    left: 52%;
  }
}
@media (max-width: 767px) {
  .hero-countdown-wrap .skill-feature:after {
    left: 61%;
  }
}
.hero-countdown-wrap .skill-feature .progress {
  border: 1px solid #1c1d34;
  background: transparent;
  height: 26px;
  border-radius: 13px;
  width: 100%;
}
.hero-countdown-wrap .skill-feature .progress .progress-bar {
  background: linear-gradient(
    270deg,
    var(--tg-primary-color) -3.08%,
    rgba(46, 32, 255, 0.27) 46.64%,
    var(--tg-black) 99.52%
  );
  height: 16px;
  border-radius: 25px;
  margin: 4px;
}
.hero-countdown-wrap .skill-feature .progress-value-max {
  flex: none;
  color: var(--tg-white);
}
@media (max-width: 767px) {
  .hero-countdown-wrap .skill-feature .progress-value-max {
    display: none;
  }
}

/* Brand area 2 ---------------------------------- */
.brand-item-wrap.style2:after {
  content: "";
  display: inline-block;
  position: absolute;
  width: 33px;
  height: 33px;
  background: var(--tg-secondary-color);
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-left: 1px solid rgba(255, 255, 255, 0.1);
  transform: rotate(45deg) translate(-50%, 50%);
  left: 50%;
  top: -17px;
  z-index: 1;
}
.brand-item-wrap.style2:before {
  display: none;
}

/* Why choose area 1 ---------------------------------- */
/*skill-feature**************/
.skill-feature {
  position: relative;
}
.skill-feature:not(:last-child) {
  margin-bottom: 27px;
}
.skill-feature_title {
  font-size: 14px;
  font-weight: 600;
  text-transform: uppercase;
  margin-bottom: 7px;
}
.skill-feature .progress {
  background: rgba(255, 255, 255, 0.12);
  border-radius: 25px;
  overflow: visible;
  height: 15px;
}
.skill-feature .progress .progress-bar {
  background: linear-gradient(
    269.91deg,
    var(--tg-primary-color) 2.78%,
    rgba(46, 32, 255, 0.27) 47.66%,
    var(--tg-secondary-color) 95.38%
  );
  border-radius: 25px;
  height: 8px;
  margin: 3.5px 4px;
}
.skill-feature .progress .progress-value {
  font-size: 14px;
  font-weight: 600;
  font-family: var(--tg-heading-font-family);
  position: relative;
  top: -29px;
  transform: translate(-100%, 0);
}
.wcu-thumb img {
  width: 256px;
  height: 256px;
  object-fit: contain;
}

.wcu-amount-quantity {
  display: flex;
  justify-content: space-around;
  text-align: center;
  margin-bottom: 48px;
}
.wcu-amount-quantity .title {
  font-size: 24px;
  font-weight: 600;
  color: var(--tg-white);
  margin-bottom: 10px;
}
@media (max-width: 575px) {
  .wcu-amount-quantity .title {
    font-size: 20px;
  }
}

.wcu-price-progress-wrap {
  list-style: none;
  padding: 0;
  margin: 0;
}
.wcu-price-progress-wrap li:not(:last-child) {
  margin-bottom: 82px;
}
.wcu-price-progress-wrap .progress-wrap-title {
  font-size: 16px;
  font-weight: 500;
  font-family: var(--tg-body-font-family);
  margin-bottom: 5px;
}
.wcu-price-progress-wrap .progress-wrap-text {
  font-size: 14px;
  color: var(--tg-white);
  margin-bottom: 26px;
}
.wcu-price-progress-wrap .skill-feature .progress:after {
  content: "";
  position: relative;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  right: 0;
  background: var(--tg-primary-color);
  transform: translate(-50%, -50%);
  top: 50%;
}
.wcu-price-progress-wrap .skill-feature .progress-value {
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 400;
  color: var(--tg-white);
  margin-right: 65px;
  margin-top: 9px;
  letter-spacing: 0.02em;
  margin-bottom: -0.3em;
}

/* Feature Card ---------------------------------- */
.feature-card {
  box-shadow: 0px 16px 18px rgba(1, 12, 50, 0.1);
  border-radius: 15px;
  background: var(--tg-black2);
  max-width: 570px;
  min-height: 206px;
  margin-left: auto;
  display: flex;
  padding: 40px;
  gap: 32px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  /* Medium devices */
  /* Extra small devices */
}
.feature-card:not(:last-child) {
  margin-bottom: 25px;
}
@media (max-width: 991px) {
  .feature-card {
    margin-left: 0;
    max-width: none;
  }
}
.feature-card-details {
  max-width: 220px;
  margin-bottom: -0.4em;
}
.feature-card-icon {
  background: var(--tg-primary-color);
  border-radius: 50%;
  flex: none;
  width: 82px;
  height: 82px;
  line-height: 82px;
  text-align: center;
}
.feature-card-icon img {
  transition: 0.4s;
}
.feature-card-title {
  font-size: 20px;
  font-weight: 600;
  letter-spacing: -0.03em;
  line-height: 1.5em;
  margin-bottom: 18px;
}
.feature-card-text {
  margin-bottom: 5px;
  font-weight: 500;
}
.feature-card-text:last-child {
  margin-bottom: 0;
}
.feature-card:hover .feature-card-icon img {
  transform: rotateY(180deg);
}
@media (max-width: 375px) {
  .feature-card {
    padding: 30px;
    gap: 20px;
    flex-wrap: wrap;
  }
}

/* Intro Area ---------------------------------- */
.intro-thumb1 {
  position: relative;
  text-align: center;
  margin-top: -130px;
}
.intro-thumb1 img {
  width: 276px;
  height: 344px;
  object-fit: contain;
}
.intro-thumb1:after {
  content: "";
  position: absolute;
  width: 479.43px;
  height: 71.62px;
  background: linear-gradient(
    0.41deg,
    var(--tg-primary-color) 7.48%,
    rgba(46, 32, 255, 0.27) 36.38%,
    var(--tg-secondary-color) 67.11%
  );
  filter: blur(23px);
  border-radius: 50%;
  bottom: 0;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: -1;
}
@media (max-width: 1199px) {
  .intro-thumb1 {
    margin-top: 70px;
  }
}

.intro-wrap:not(:last-child) {
  margin-bottom: 70px;
}
.intro-wrap .intro-wrap-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 30px;
}
.intro-wrap .intro-wrap-title:before {
  content: "";
  position: relative;
  display: inline-block;
  height: 14px;
  width: 14px;
  border-radius: 50%;
  background: var(--tg-primary-color);
  margin-right: 9px;
}
.intro-wrap .intro-wrap-text {
  margin-bottom: -0.4em;
}

/* Roadmap 2 ---------------------------------- */
.roadmap-slider1 {
  max-width: 1870px;
  margin-right: -255px;
  margin-left: auto;
}
.roadMap-active2 .col-lg-4 {
  padding: 0 15px;
}
@media (min-width: 1922px) {
  .roadmap-slider1 {
    max-width: 2114px;
    margin-right: auto;
  }
}
@media (max-width: 1700px) {
  .roadmap-slider1 {
    margin-right: -370px;
  }
}
@media (max-width: 1500px) {
  .roadmap-slider1 {
    margin-left: 55px;
    margin-right: -440px;
  }
}
@media (max-width: 1399px) {
  .roadmap-slider1 {
    max-width: 1388px;
    margin-left: 20px;
    margin-right: -180px;
  }
}
@media (max-width: 1299px) {
  .roadmap-slider1 {
    max-width: 1328px;
    margin-left: 35px;
  }
}
@media (max-width: 1199px) {
  .roadmap-slider1 {
    max-width: 1110px;
  }
}
@media (max-width: 991px) {
  .roadmap-slider1 {
    max-width: none;
    margin: 0;
  }
}
@media (max-width: 767px) {
  .roadmap-slider1 {
    max-width: 540px;
    margin: 0 auto;
  }
}
@media (max-width: 575px) {
  .roadmap-slider1 {
    margin: 40px -15px 0;
    max-width: none;
  }
}

/*------------------- 4.00. Invest -------------------*/
.invest-card {
  text-align: center;
  background: var(--tg-secondary-color2);
  border-radius: 15px;
  padding: 70px 60px;
  border: 1px solid rgba(255, 255, 255, 0.12);
}
.invest-card .invest-card-icon {
  border-radius: 50%;
  width: 99px;
  height: 99px;
  margin: auto;
  margin-bottom: 70px;
}
.invest-card .btn {
  width: 100%;
}
@media (max-width: 1399px) {
  .invest-card {
    padding: 50px 40px;
  }
}
@media (max-width: 1199px) {
  .invest-card {
    padding: 50px 30px;
  }
  .invest-card .invest-card-icon {
    margin-bottom: 50px;
  }
  .invest-card .btn {
    padding-left: 30px;
    padding-right: 30px;
  }
}
/* Founder 1 ---------------------------------- */
.founder-card {
  background: var(--tg-secondary-color2);
  border-radius: 15px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  display: flex;
  gap: 40px;
  align-items: center;
  padding: 50px 65px;
}
.founder-card .founder-card-img {
  mask-image: url(../images/HomeImages/update/team/team-mask.png);
  mask-size: 100% 100%;
  height: 235px;
  width: 220px;
  flex: none;
}
.founder-card .founder-card-img img {
  transition: 0.4s;
}
.founder-card .founder-card-title {
  font-size: 35px;
  font-weight: 600;
  color: var(--tg-white);
  letter-spacing: -0.03em;
  margin-bottom: 15px;
  transition: 0.3s;
}
.founder-card .founder-card-title a {
  color: inherit;
}
.founder-card .founder-card-title:hover {
  color: var(--tg-primary-color);
}
.founder-card .founder-card-desig {
  font-size: 14px;
  font-weight: 500;
}
.founder-card .social-btn a {
  background: var(--tg-white);
  color: #5729d6;
  font-size: 18px;
}
.founder-card .social-btn a:hover {
  background: rgba(255, 255, 255, 0.1);
  color: var(--tg-white);
}
.founder-card:hover .founder-card-img img {
  transform: scale(1.05);
}
@media (max-width: 1399px) {
  .founder-card {
    padding: 50px 55px;
  }
  .founder-card .founder-card-title {
    font-size: 28px;
  }
}
@media (max-width: 1199px) {
  .founder-card {
    padding: 40px 45px;
  }
  .founder-card .founder-card-img {
    height: 155px;
    width: 140px;
  }
}
@media (max-width: 575px) {
  .founder-card {
    padding: 40px;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    gap: 20px;
  }
  .founder-card .social-btn {
    justify-content: center;
  }
}
/* Team 1 ---------------------------------- */
.team-tab-btn {
  display: inline-flex;
  gap: 30px 130px;
  padding-bottom: 30px;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  margin-bottom: 80px;
  flex-wrap: wrap;
}
.team-tab-btn button {
  font-size: 24px;
  font-weight: 700;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-white);
  letter-spacing: -0.03em;
  border: 0;
  background: transparent;
  opacity: 0.4;
  transition: 0.4s;
  position: relative;
  padding: 0;
}
.team-tab-btn button:after {
  content: "";
  position: absolute;
  height: 3px;
  width: 0;
  background: var(--tg-primary-color);
  bottom: -30px;
  left: 0;
  border-radius: 15px;
  transition: 0.4s;
}
.team-tab-btn button.active {
  opacity: 1;
}
.team-tab-btn button.active:after {
  width: 100%;
}
@media (max-width: 991px) {
  .team-tab-btn {
    gap: 30px 50px;
  }
}
@media (max-width: 767px) {
  .team-tab-btn {
    gap: 30px 40px;
    justify-content: center;
  }
  .team-tab-btn button {
    font-size: 20px;
    width: 28%;
  }
}
@media (max-width: 575px) {
  .team-tab-btn {
    gap: 30px 10px;
  }
  .team-tab-btn button {
    font-size: 18px;
  }
  .team-tab-btn button:after {
    display: none;
  }
}
@media (max-width: 375px) {
  .team-tab-btn button {
    font-size: 16px;
    width: 29%;
  }
}
@media (max-width: 320px) {
  .team-tab-btn button {
    width: 31%;
  }
}

.team-tab-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  width: 100%;
  gap: 80px;
  flex-wrap: wrap;
  justify-content: center;
}
.team-tab-list li {
  width: calc(20% - 65px);
}
@media (max-width: 1399px) {
  .team-tab-list li {
    width: calc(25% - 65px);
  }
}
@media (max-width: 1299px) {
  .team-tab-list li {
    width: calc(25% - 40px);
  }
}
@media (max-width: 991px) {
  .team-tab-list {
    gap: 60px;
  }
  .team-tab-list li {
    width: calc(33% - 42px);
  }
}
@media (max-width: 767px) {
  .team-tab-list {
    gap: 50px 30px;
  }
  .team-tab-list li {
    width: calc(45% - 42px);
  }
}
@media (max-width: 575px) {
  .team-tab-list li {
    width: 100%;
  }
}

.team-tab-content {
  width: 100%;
}

.team-card {
  overflow: hidden;
  position: relative;
  text-align: center;
  width: 100%;
}
.team-card_img {
  position: relative;
  display: inline-block;
  overflow: hidden;
  width: 184px;
  height: 194px;
  mask-image: url(../images/HomeImages/update/team/team-mask.png);
  mask-size: 100% 100%;
  margin-bottom: 20px;
}
.team-card_img img {
  transition: 0.4s;
  width: 100%;
}
.team-card_img .social-btn {
  position: absolute;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
}
.team-card_title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 0;
}
.team-card_title a {
  color: var(--tg-white);
}
.team-card_title a:hover {
  color: var(--tg-primary-color);
}
.team-card .social-btn a {
  --icon-size: 32px;
  line-height: 32px;
  background: var(--tg-white);
  color: var(--tg-secondary-color);
}
.team-card .social-btn a:hover {
  background: var(--tg-primary-color);
  color: var(--tg-white);
}
.team-card:hover .team-card_img img {
  transform: scale(1.05);
  filter: grayscale(0);
}

/* Partner area 1 ---------------------------------- */
.testimonial-slider1 .slick-track > [class*="col"],
.partner-slider1 .slick-track > [class*="col"] {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}
.partner-card {
  border-radius: 8px;
  padding: 70px 90px 90px;
  background: linear-gradient(
    180deg,
    var(--tg-secondary-color) 0%,
    rgba(1, 3, 20, 0) 100%
  );
  text-align: center;
  position: relative;
  transition: 0.4s;
  overflow: hidden;
  max-width: 360px;
}
.partner-card:after {
  content: "";
  position: absolute;
  inset: 0;
  background: linear-gradient(
    180deg,
    var(--tg-primary-color) -20.97%,
    rgba(46, 32, 255, 0.2) 65%,
    rgba(1, 3, 20, 0) 100%
  );
  border-radius: 16px;
  height: 0;
  transition: 0.4s;
  z-index: -1;
}
.partner-card .partner-card-img {
  margin-bottom: 38px;
  height: 43px;
}
.partner-card .partner-card-img img {
  margin: auto;
}
.partner-card .partner-card-text {
  margin-bottom: 112px;
  transition: 0.4s;
}
.partner-card .btn {
  min-width: 160px;
}
.partner-card:hover {
  border-radius: 16px;
  background: transparent;
}
.partner-card:hover:after {
  height: 100%;
}
.partner-card:hover .partner-card-text {
  color: var(--tg-white);
}
@media (max-width: 1399px) {
  .partner-card {
    padding: 70px;
  }
  .partner-card .partner-card-text {
    margin-bottom: 60px;
  }
}
@media (max-width: 767px) {
  .partner-card {
    background: linear-gradient(
      180deg,
      var(--tg-primary-color) -20.97%,
      rgba(46, 32, 255, 0.2) 65%,
      rgba(1, 3, 20, 0) 100%
    );
    border-radius: 16px;
  }
  .partner-card:after {
    display: none;
  }
  .partner-card .partner-card-text {
    color: var(--tg-white);
  }
  .partner-card:hover {
    background: linear-gradient(
      180deg,
      var(--tg-primary-color) -20.97%,
      rgba(46, 32, 255, 0.2) 65%,
      rgba(1, 3, 20, 0) 100%
    );
  }
}
@media (max-width: 375px) {
  .partner-card {
    padding: 50px;
  }
}

/*Event area 1***********************/
.event-card-img {
  overflow: hidden;
  border-radius: 8px;
  margin-bottom: 30px;
}
.event-card-img a {
  display: inline-block;
  width: 100%;
}
.event-card-img a img {
  transition: 0.5s;
  width: 100%;
}
.event-card-title {
  font-size: 22px;
  font-weight: 600;
  margin-bottom: 11px;
}
.event-card-title a {
  color: inherit;
}
.event-card-title:hover {
  color: var(--tg-primary-color);
}
.event-card .event-meta a {
  display: flex;
  gap: 8px;
  color: var(--tg-white);
  align-items: center;
}
.event-card .event-meta a i {
  color: var(--tg-primary-color);
}
.event-card .event-meta a:not(:last-child) {
  margin-bottom: 10px;
}
.event-card .event-meta a:hover {
  color: var(--tg-primary-color);
}
.event-card .btn {
  margin-top: 30px;
  letter-spacing: -0.01em;
  min-width: 155px;
  padding: 11.5px 40px;
}
.event-card:hover .event-card-img img {
  transform: scale(1.05);
}
@media (max-width: 1199px) {
  .event-card .event-card-title {
    font-size: 20px;
  }
}

/* CTA Area 1 ---------------------------------- */
.cta-slider1 .slick-track > [class*="col"] {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}
.cta-wrap1 {
  background: var(--tg-black2);
  border-radius: 16px;
  padding: 70px 50px;
  display: flex;
  gap: 40px 18px;
}
.cta-wrap1 .cta-wrap-title {
  font-size: 28px;
  font-weight: 600;
  margin-top: 30px;
}
.cta-wrap1 .cta-wrap-text {
  margin-bottom: -0.4em;
}
.cta-wrap1 .btn {
  flex: none;
  align-self: start;
}
@media (max-width: 1199px) {
  .cta-wrap1 {
    flex-wrap: wrap;
    padding: 60px 40px;
  }
}
@media (max-width: 767px) {
  .cta-wrap1 {
    padding: 60px 50px;
  }
}
@media (max-width: 375px) {
  .cta-wrap1 {
    padding: 40px 30px;
  }
}

/* Faq 1 ---------------------------------- */
.accordion-card {
  transition: 0.4s ease-in-out;
  overflow: hidden;
  background: var(--tg-secondary-color2);
  border-radius: 33px;
  border: 1px solid rgba(255, 255, 255, 0.12);
  padding: 18px 30px;
  position: relative;
}
.accordion-card:not(:last-child) {
  margin-bottom: 24px;
}
.accordion-card .accordion-header {
  flex: none;
}
.accordion-card .accordion-button {
  font-size: 18px;
  font-weight: 600;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-white);
  border: 0;
  background-color: transparent;
  padding: 0px;
  gap: 15px;
  margin-bottom: 0;
  text-align: left;
  transition: 0.3s;
  align-items: start;
  position: inherit;
}
.accordion-card .accordion-button .number {
  height: 26px;
  width: 26px;
  line-height: 26px;
  border-radius: 50%;
  font-size: 16px;
  font-weight: 600;
  color: var(--tg-primary-color) !important;
  text-align: center;
  background: linear-gradient(
    38deg,
    var(--tg-primary-color),
    rgba(46, 32, 255, 0.27),
    var(--tg-secondary-color)
  ) !important;
  position: relative;
  z-index: 1;
  flex: none;
}
.accordion-card .accordion-button .number:after {
  content: "";
  position: absolute;
  inset: 1px;
  background: var(--tg-secondary-color2);
  border-radius: 50%;
  z-index: -1;
  transition: 0.4s;
}
.accordion-card .accordion-button:after {
  content: "\f067";
  width: auto;
  background: transparent;
  border: 0;
  font-family: "Font Awesome 5 Free";
  color: var(--tg-white);
  font-weight: 700;
  font-size: 16px;
  display: inline-block;
  justify-content: center;
  align-items: center;
  text-align: center;
  position: absolute;
  top: 18px;
  right: 30px;
  transition: 0.3s ease-in-out;
  transform: rotateY(180deg);
}
.accordion-card .accordion-button .faq-number {
  font-size: 14px;
  font-weight: 600;
  margin-right: 40px;
}
.accordion-card .accordion-button:focus {
  outline: none;
  box-shadow: none;
}
.accordion-card .accordion-button:not(.collapsed):after {
  content: "\f068";
  transform: rotate(0);
}
.accordion-card .accordion-button:not(.collapsed) .number {
  background: var(--tg-white);
  color: var(--tg-white);
}
.accordion-card .accordion-collapse {
  border: none;
  position: relative;
}
.accordion-card .accordion-body {
  border-radius: 0;
  background-color: transparent;
  border: none;
  padding: 0px;
  margin: 10px 35px;
}
.accordion-card .accordion-body p {
  margin-bottom: 0;
  font-weight: 400;
  font-size: 16px;
}
.accordion-card:last-child {
  margin-bottom: 0;
}

/* Medium devices */
@media (max-width: 991px) {
  .accordion-card {
    display: block;
  }
  .accordion-card .accordion-body {
    margin-top: 20px;
    max-width: none;
  }
}
/* Extra small devices */
@media (max-width: 375px) {
  .accordion-card .accordion-body {
    margin: 10px 0;
  }
}

/*footer 1************/
.copyright-wrap {
  padding: 12px 0 50px;
  /* Medium devices */
}
@media (max-width: 991px) {
  .copyright-wrap {
    padding: 22px 0;
  }
}
.copyright-text {
  margin: 0;
  color: var(--tg-white);
  font-weight: 500;
  font-size: 14px;
}
.copyright-text a {
  color: var(--tg-white);
}
.copyright-text a:hover {
  color: var(--tg-primary-color);
}

.footer-links {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 15px 40px;
  justify-content: center;
  /* Extra small devices */
}
.footer-links li {
  display: inline-block;
}
.footer-links li a {
  display: block;
  position: relative;
  font-weight: 500;
  font-size: 14px;
  font-family: var(--tg-body-font-family);
  color: var(--tg-white);
}
.footer-links li a:hover {
  color: var(--tg-primary-color);
}
@media (max-width: 575px) {
  .footer-links {
    gap: 15px 30px;
  }
}
.footer-menu-area {
  border-bottom: 1px solid var(--tg-white);
  padding: 0 0 57px;
}
.footer-menu-area .footer-menu-list {
  list-style: none;
  padding: 0;
  margin: 0;
  display: inline-flex;
  flex-wrap: wrap;
  gap: 15px 45px;
}
.footer-menu-area .footer-menu-list li {
  display: inline-block;
}
.footer-menu-area .footer-menu-list li a {
  display: block;
  position: relative;
  font-weight: 600;
  font-size: 14px;
  font-family: var(--tg-body-font-color);
  color: var(--tg-white);
  text-transform: uppercase;
  letter-spacing: 0.14em;
}
.footer-menu-area .footer-menu-list li a:hover {
  color: var(--tg-primary-color);
}
@media (max-width: 1199px) {
  .footer-menu-area .footer-menu-list {
    gap: 15px 28px;
  }
}
@media (max-width: 991px) {
  .footer-menu-area {
    padding: 0 0 27px;
  }
}
@media (max-width: 767px) {
  .footer-menu-area .footer-menu-list {
    justify-content: center;
  }
}
@media (max-width: 575px) {
  .footer-menu-area .footer-menu-list {
    display: block;
  }
  .footer-menu-area .footer-menu-list li {
    display: block;
  }
  .footer-menu-area .footer-menu-list li:not(:last-child) {
    margin-bottom: 10px;
  }
}

/* Home 3 ---------------------------------- */
.sub-title {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.02em;
  background: linear-gradient(
    90deg,
    var(--tg-primary-color) -18.55%,
    var(--tg-theme-color2) 163.09%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
  margin-top: -0.4em;
  position: relative;
  top: -6px;
  margin-bottom: 0px;
}
.sub-title.text-white {
  background: transparent;
  -webkit-text-fill-color: var(--white-color);
}
.sub-title.text-title {
  background: transparent;
  -webkit-text-fill-color: var(--title-color);
  color: var(--tg-secondary-color);
}
/* Hero 2 -------------------- */
.hero-2 {
  position: relative;
  padding: 293px 0 250px;
  overflow: hidden;
}
@media (max-width: 1399px) {
  .hero-2 {
    padding: 253px 0 180px;
  }
}
@media (max-width: 1199px) {
  .hero-2 {
    padding: 200px 0 150px;
  }
}
@media (max-width: 575px) {
  .hero-2 {
    padding: 170px 0 100px;
  }
}
.hero-2 .hero-bg-gradient1 {
  width: 969px;
  height: 722px;
  position: absolute;
  background: radial-gradient(
    48.69% 39.15% at 50.02% 50.04%,
    rgba(205, 34, 248, 0.5) 0.01%,
    rgba(200, 9, 249, 0.3) 23.96%,
    rgba(66, 0, 83, 0) 90%
  );
  top: -5%;
  right: 0;
  transform: rotate(40deg);
  z-index: -1;
}
@media (max-width: 1199px) {
  .hero-2 .hero-bg-gradient1 {
    right: -40%;
  }
}
.hero-2 .hero-bg-gradient2 {
  width: 721px;
  height: 539px;
  position: absolute;
  background: radial-gradient(
    48.69% 39.15% at 50.02% 50.04%,
    #53ffc1 0.01%,
    rgba(25, 54, 99, 0.3) 53.96%,
    rgba(0, 0, 0, 0) 100%
  );
  top: 25%;
  left: -448px;
  transform: rotate(40deg);
  opacity: 0.7;
  z-index: -1;
}
.hero-2 .hero-gradient-ball {
  background: radial-gradient(
    114.99% 94.16% at 72.92% 82.18%,
    #111111 47.39%,
    #091534 68.23%,
    #a24dd6 86.63%
  );
  height: 350px;
  width: 350px;
  border-radius: 50%;
  position: absolute;
  right: 40px;
  bottom: 10%;
  z-index: -1;
  opacity: 0.5;
}
@media (max-width: 1399px) {
  .hero-2 .hero-gradient-ball {
    height: 250px;
    width: 250px;
  }
}
@media (max-width: 767px) {
  .hero-2 .hero-gradient-ball {
    height: 150px;
    width: 150px;
  }
}
.hero-2 .ripple-shape {
  transform: skewX(220deg);
  position: relative;
  z-index: -1;
  top: -300px;
  left: 240px;
  opacity: 0.3;
}
.hero-2 .ripple-1,
.hero-2 .ripple-2,
.hero-2 .ripple-3,
.hero-2 .ripple-4,
.hero-2 .ripple-5,
.hero-2 .ripple-6,
.hero-2 .ripple-7 {
  width: 700px;
  height: 700px;
  position: absolute;
  left: 30%;
  top: 0;
  transform: translate(-50%, -50%);
  background-color: transparent;
  border: 1px solid var(--tg-primary-color);
  border-radius: 50%;
  animation: ripple2 10s linear infinite;
  opacity: 0;
  transition: 0.4s;
}
.hero-2 .ripple-1 {
  animation-delay: 0;
}
.hero-2 .ripple-2 {
  animation-delay: 1s;
}
.hero-2 .ripple-3 {
  animation-delay: 3s;
}
.hero-2 .ripple-4 {
  animation-delay: 4s;
}
.hero-2 .ripple-4 {
  animation-delay: 5s;
}
.hero-2 .ripple-5 {
  animation-delay: 6s;
}
.hero-2 .ripple-6 {
  animation-delay: 7s;
}
.hero-2 .ripple-7 {
  animation-delay: 8s;
}

.hero-style2 .hero-subtitle {
  font-size: 16px;
  font-weight: 600;
  letter-spacing: -0.02em;
  background: linear-gradient(
    90deg,
    var(--tg-primary-color) -18.55%,
    var(--tg-theme-color2) 163.09%
  );
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  display: inline-block;
}
.hero-style2 .hero-title {
  font-size: 220px;
  font-weight: 700;
  letter-spacing: -0.04em;
  line-height: 1;
  margin-left: -11px;
  margin-top: -11px;
  margin-bottom: 65px;
}
.hero-style2 .hero-title span {
  position: relative;
}
.hero-style2 .hero-title span .bitcoin {
  position: absolute;
  top: 52px;
  left: 5px;
}
.hero-style2 .btn-wrap {
  margin-top: 43px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 70px;
}
@media (max-width: 1399px) {
  .hero-style2 .hero-title {
    font-size: 170px;
  }
  .hero-style2 .hero-title span .bitcoin {
    top: 40px;
    left: 4px;
  }
}
@media (max-width: 1299px) {
  .hero-style2 .hero-title {
    font-size: 150px;
  }
}
@media (max-width: 1199px) {
  .hero-style2 .hero-title {
    font-size: 120px;
    margin-bottom: 55px;
    margin-top: 0;
    margin-left: 0;
  }
  .hero-style2 .hero-title span .bitcoin {
    top: 29px;
    left: 3px;
  }
}
@media (max-width: 991px) {
  .hero-style2 .hero-title {
    font-size: 90px;
  }
  .hero-style2 .hero-title span .bitcoin {
    top: 20px;
    left: 2px;
  }
}
@media (max-width: 575px) {
  .hero-style2 .hero-title {
    font-size: 60px;
    letter-spacing: normal;
    margin-bottom: 35px;
  }
  .hero-style2 .hero-title span .bitcoin {
    top: 11px;
    left: 0px;
  }
}
@media (max-width: 375px) {
  .hero-style2 .hero-title {
    font-size: 50px;
    margin-bottom: 30px;
  }
  .hero-style2 .hero-title span .bitcoin {
    top: 10px;
    left: 0px;
  }
}
.hero-style2 .btn-wrap {
  gap: 70px;
}
@media (max-width: 1199px) {
  .hero-style2 .btn-wrap {
    gap: 50px 70px;
  }
}
@media (max-width: 575px) {
  .hero-style2 .btn-wrap {
    gap: 30px 70px;
  }
}
.hero-style2 .hero-title2 {
  display: inline-block;
  font-size: 56px;
  font-weight: 700;
  line-height: 1.607;
  background: linear-gradient(180deg, #e5f8c6 0%, #61b998 100%);
  border-radius: 25px;
  color: var(--tg-secondary-color);
  padding: 24px 40px;
  margin-bottom: 0;
}
@media (max-width: 1399px) {
  .hero-style2 .hero-title2 {
    font-size: 40px;
    padding: 24px 30px;
  }
}
@media (max-width: 767px) {
  .hero-style2 .hero-title2 {
    font-size: 30px;
    padding: 14px 30px;
  }
}
@media (max-width: 575px) {
  .hero-style2 .hero-title2 {
    font-size: 24px;
    padding: 14px 30px;
    border-radius: 15px;
  }
}
@media (max-width: 375px) {
  .hero-style2 .hero-title2 {
    font-size: 20px;
    padding: 14px 20px;
  }
}
.hero-style2 .hero-content {
  max-width: 445px;
}
.hero-style2 .hero-text {
  margin-bottom: 29px;
  margin-top: -0.3em;
}
.spin {
  animation: spin 15s linear infinite;
}
@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.brand-title2 .title {
  text-transform: capitalize;
  font-size: 24px;
  font-weight: 500;
  letter-spacing: -0.015em;
  margin-bottom: 55px;
  margin-top: -0.3em;
}

/* Feature Area 02 ---------------------------------- */
.checklist ul {
  padding-left: 0;
  list-style: none;
  text-align: left;
  margin-bottom: 0;
}
.checklist li {
  font-size: 16px;
  font-weight: 400;
  margin-bottom: 5px;
  display: flex;
  color: var(--tg-body-font-color);
}
.checklist li:last-child {
  margin-bottom: 0;
}
.checklist li i,
.checklist li svg {
  font-size: 16px;
  margin-right: 10px;
  color: var(--tg-primary-color);
  margin-top: 6px;
  width: 16px;
}
.checklist.style2 ul li {
  font-weight: 700;
  border-radius: 6px;
  background-color: rgb(247, 244, 239);
  padding: 17px 30px;
  font-family: var(--tg-heading-font-family);
}
.checklist.style2 ul li:not(:last-child) {
  margin-bottom: 20px;
}
.checklist.style2 ul li i {
  margin-right: 20px;
}
.checklist.style3 ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 8px;
}
.checklist.style3 ul li {
  margin-bottom: 0;
}
.checklist.style4 ul li:not(:last-child) {
  margin-bottom: 9px;
}
.checklist.style5 ul li {
  font-weight: 400;
  font-size: 16px;
  color: var(--tg-body-font-color);
}
.feature-area-shape {
  position: absolute;
  left: -100px;
  top: 200px;
}
.feature-area-shape .feature-shape2-2 {
  position: absolute;
  bottom: -30px;
  right: 30px;
}
.feature-area-shape .feature-shape2-3 {
  position: absolute;
  right: 0;
  top: 0;
}

.feature-grid-wrap {
  display: grid;
  grid-template-areas: "one one one one one two two two two two two two" "one one one one one three three three three three three three";
}

.feature-card-grid {
  display: flex;
  position: relative;
  border-radius: 25px;
  background: #13141e;
  align-items: center;
}
.feature-card-grid .feature-card-details {
  padding: 100px 65px 100px;
  max-width: none;
}
.feature-card-grid .feature-card-title {
  font-size: 45px;
  font-weight: 500;
  margin-bottom: 32px;
  margin-top: -0.4em;
}
.feature-card-grid .feature-card-text {
  font-weight: 400;
  margin-bottom: -0.3em;
}
.feature-card-grid .feature-card-text:not(:last-child) {
  margin-bottom: 35px;
}
.feature-card-grid .checklist li {
  color: var(--tg-white);
  font-size: 16px;
  font-weight: 400;
}
.feature-card-grid .checklist li:not(:last-child) {
  margin-bottom: 10px;
}
.feature-card-grid .checklist li i {
  color: var(--tg-white);
  font-size: 6px;
  margin-right: 4px;
  margin-top: 11px;
}
.feature-card-grid .feature-card-img {
  transform: translate(134px, 0);
  margin-left: -134px;
  flex: none;
}
.feature-card-grid:nth-child(1) {
  grid-area: one;
  margin-right: 20px;
  display: block;
  padding-bottom: 65px;
}
.feature-card-grid:nth-child(1) .feature-card-img {
  transform: translate(-90px, 0);
  margin-left: 0;
}
.feature-card-grid:nth-child(2) {
  grid-area: two;
  margin-left: 20px;
  margin-bottom: 40px;
}
.feature-card-grid:nth-child(3) {
  grid-area: three;
  margin-left: 12px;
}

/* Extra large devices */
@media (max-width: 1500px) {
  .feature-card-grid:nth-child(2),
  .feature-card-grid:nth-child(3) {
    margin-right: 90px;
  }
  .feature-card-grid:nth-child(2) .feature-card-details,
  .feature-card-grid:nth-child(3) .feature-card-details {
    padding-right: 40px;
  }
}
/* Medium Large devices */
@media (max-width: 1399px) {
  .feature-card-grid .feature-card-title {
    font-size: 35px;
  }
  .feature-card-grid .feature-card-details {
    padding: 60px 40px;
  }
  .feature-card-grid .feature-card-img {
    transform: none;
    margin: 30px 30px 30px 0;
  }
  .feature-card-grid .feature-card-img img {
    animation: none !important;
  }
  .feature-card-grid:nth-child(1) .feature-card-img {
    transform: none;
  }
  .feature-card-grid:nth-child(2),
  .feature-card-grid:nth-child(3) {
    margin-right: 0;
  }
}
/* Large devices */
@media (max-width: 1199px) {
  .feature-grid-wrap {
    grid-template-areas: "one one one" "two two two" "three three three";
  }
  .feature-card-grid:nth-child(1) {
    margin-right: 0;
    margin-bottom: 40px;
  }
  .feature-card-grid:nth-child(2) {
    margin-left: 0;
  }
  .feature-card-grid:nth-child(3) {
    margin-left: 0;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .feature-card-grid {
    display: block;
  }
  .feature-card-grid .feature-card-img {
    margin: 0 40px 60px;
  }
  .feature-card-grid:nth-child(1) {
    padding-bottom: 0;
  }
}
/* Extra small devices */
@media (max-width: 575px) {
  .feature-card-grid .feature-card-details {
    padding: 40px 30px;
  }
  .feature-card-grid .feature-card-img {
    margin: 0 30px 40px;
  }
}
/* Feature Area 03 ---------------------------------- */
.feature-shape-3-1 {
  position: absolute;
  width: 246px;
  height: 246px;
  border-radius: 50%;
  background: radial-gradient(
    49.88% 55.25% at 50% 50%,
    #200f31 0%,
    #82118c 223.72%
  );
  background: radial-gradient(
    50% 45.25% at 50% 50%,
    #200f31 60%,
    #82118c 153.72%
  );
  opacity: 0.5;
  left: 0;
  bottom: 80px;
  margin-left: -160px;
  z-index: -1;
}

.feature-shape-3-2 {
  position: absolute;
  width: 201px;
  height: 201px;
  border-radius: 50%;
  background: radial-gradient(
    119.88% 115.25% at 50% 50%,
    #200f31 20%,
    #82118c 63.72%
  );
  opacity: 0.7;
  bottom: 135px;
  right: -135px;
  z-index: -1;
}

.feature-thumb-wrap {
  position: relative;
  display: inline-block;
  padding: 0 60px;
  margin-bottom: -23px;
  /* Large devices */
}
.feature-thumb-wrap .feature-thumb-3-1 {
  width: 383px;
}
.feature-thumb-wrap .feature-thumb-3-2 {
  position: absolute;
  left: -50px;
  z-index: -1;
}
.feature-thumb-wrap .feature-thumb-circle {
  position: absolute;
  width: 153px;
  height: 153px;
  border-radius: 50%;
  background: radial-gradient(
    114.99% 94.16% at 72.92% 92.18%,
    #111111 47.39%,
    #091534 68.23%,
    #a24dd6 86.63%
  );
  bottom: 60px;
  z-index: -1;
}
@media (max-width: 1199px) {
  .feature-thumb-wrap {
    margin-bottom: 0;
  }
}

/* Feature Area 04 ---------------------------------- */
.feature-shape-4-1 {
  position: absolute;
  background: radial-gradient(
    144.03% 144.03% at 41.08% 9.08%,
    #111111 47.39%,
    #a24f2c 100%
  );
  width: 135px;
  height: 135px;
  border-radius: 50%;
  right: 12%;
  top: 30%;
  z-index: -1;
}

.feature-shape-4-2 {
  position: absolute;
  right: 20px;
  bottom: 0;
  background: radial-gradient(
    104.92% 104.68% at 58.37% -0.84%,
    #ef994a 0%,
    rgba(255, 111, 111, 0.536826) 25.52%,
    rgba(212, 39, 255, 0) 55.1%
  );
  width: 247px;
  height: 247px;
  border-radius: 50%;
  z-index: -1;
}

.feature-shape-4-3 {
  position: absolute;
  left: -43px;
  bottom: 250px;
  background: radial-gradient(
    165.58% 165.58% at 54.64% -34.27%,
    #0c0c0c 47.39%,
    #2f3a55 89.06%
  );
  width: 215px;
  height: 215px;
  border-radius: 50%;
  z-index: -1;
}

.feature-shape-4-4 {
  position: absolute;
  left: -530px;
  bottom: -80px;
  background: radial-gradient(
    48.69% 39.15% at 50.02% 50.04%,
    #cd22f8 0.01%,
    rgba(200, 9, 249, 0.3) 53.96%,
    rgba(66, 0, 83, 0) 100%
  );
  height: 960px;
  width: 722px;
  border-radius: 50%;
  transform: skew(30deg, 10deg);
  z-index: -1;
}

.feature-category-list {
  list-style: none;
  padding: 0;
  gap: 45px 80px;
  display: flex;
  flex-wrap: wrap;
  /* Extra small devices */
}
.feature-category-list li {
  width: calc(50% - 40px);
}
.feature-category-list a {
  font-size: 28px;
  font-weight: 600;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-body-font-color);
  text-decoration: underline;
}
.feature-category-list a:hover {
  color: var(--tg-white);
}
@media (max-width: 575px) {
  .feature-category-list {
    gap: 40px;
  }
  .feature-category-list a {
    font-size: 18px;
  }
}

.feature-wrap4-thumb {
  /* Extra small devices */
}
.feature-wrap4-thumb img {
  width: 450px;
  height: 450px;
  object-fit: contain;
}
@media (max-width: 575px) {
  .feature-wrap4-thumb img {
    width: auto;
    height: auto;
    object-fit: contain;
  }
}

.feature-wrap-4 {
  background: linear-gradient(180deg, #e5f8c6 0%, #61b998 100%);
  border-radius: 30px;
  padding: 60px 100px;
}
@media (max-width: 1199px) {
  .feature-wrap-4 {
    padding: 60px 50px;
  }
}
@media (max-width: 991px) {
  .feature-wrap-4 {
    padding: 60px;
  }
}
@media (max-width: 767px) {
  .feature-wrap-4 .title-area .btn {
    margin-top: 16px;
  }
}
@media (max-width: 575px) {
  .feature-wrap-4 {
    padding: 40px 30px;
  }
}

/* Counter area ---------------------------------- */
.counter-grid-wrap {
  display: flex;
  gap: 30px 60px;
}

.counter-card_number {
  font-size: 65px;
  font-weight: 600;
  font-family: var(--body-font);
  margin-top: -0.3em;
  margin-bottom: 4px;
  background: linear-gradient(180deg, #e5f8c6 0%, #61b998 100%);
  background-clip: text;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.counter-card_text {
  font-size: 18px;
  font-weight: 500;
  margin-bottom: -0.4em;
}

/* Medium Large devices */
@media (max-width: 1399px) {
  .counter-card_number {
    font-size: 45px;
  }
}
/* Small devices */
@media (max-width: 767px) {
  .counter-card_number {
    font-size: 40px;
  }
}

/* Testimonial 1 ---------------------------------- */
.testimonial-wrap-3 {
  background: radial-gradient(
    298.93% 263.81% at 149.5% 261.83%,
    #111111 47.39%,
    #091534 68.23%,
    rgba(162, 77, 214, 0.5) 86.63%
  );
  border-radius: 30px;
  position: relative;
  padding: 160px 195px;
}
.testimonial-wrap-3 .testimonial-wrap-bg {
  position: absolute;
  bottom: 0;
  right: 0;
}
.testimonial-wrap-3 .testimonial-wrap-circle1 {
  position: absolute;
  background: radial-gradient(
    120.5% 130.5% at -19.95% 33.74%,
    #190f2e 77.39%,
    #7d4087 114.59%
  );
  width: 161px;
  height: 161px;
  border-radius: 50%;
  right: -60px;
  top: -80px;
  z-index: -1;
  filter: blur(3px);
}
.testimonial-wrap-3 .testimonial-wrap-circle2 {
  position: absolute;
  background: radial-gradient(
    139.08% 139.08% at 6.17% 127.08%,
    #130f1a 57.39%,
    #3b2a5e 112.32%,
    #8543b1 139.14%
  );
  width: 158px;
  height: 158px;
  border-radius: 50%;
  left: -90px;
  top: 80px;
  z-index: -1;
  filter: blur(4px);
}
.testimonial-wrap-3 .testimonial-wrap-circle3 {
  position: absolute;
  background: radial-gradient(
    107.91% 147.91% at 140.21% 149.09%,
    #ab432c 0%,
    #460615 10.9%,
    #543871 156.63%
  );
  filter: blur(6.66516px);
  width: 85px;
  height: 85px;
  border-radius: 50%;
  z-index: -1;
  top: 250px;
  right: 30px;
}
@media (max-width: 1399px) {
  .testimonial-wrap-3 {
    padding: 130px 135px;
  }
}
@media (max-width: 1299px) {
  .testimonial-wrap-3 {
    padding: 100px 100px;
  }
}
@media (max-width: 1199px) {
  .testimonial-wrap-3 {
    padding: 100px 80px;
  }
}
@media (max-width: 991px) {
  .testimonial-wrap-3 {
    padding: 80px;
  }
}
@media (max-width: 767px) {
  .testimonial-wrap-3 {
    padding: 60px;
  }
}
@media (max-width: 575px) {
  .testimonial-wrap-3 {
    padding: 30px;
  }
}

.testi-box_text {
  margin-bottom: -0.1em;
  line-height: 1.357;
  font-size: 28px;
  font-family: var(--tg-heading-font-family);
  font-weight: 600;
  margin-top: 76px;
  color: var(--tg-white);
}
.testi-box-profile {
  display: flex;
  gap: 12px;
  align-items: center;
}
.testi-box-profile .testi-box-profile-thumb {
  flex: none;
}
.testi-box-profile .testi-box-profile-thumb img {
  border-radius: 50%;
}
.testi-box-profile .testi-box_name {
  margin-bottom: 0px;
  margin-top: -0.35em;
  font-weight: 700;
  font-size: 18px;
  font-family: var(--tg-heading-font-family);
  color: var(--tg-white);
}
.testi-box-profile .testi-box_desig {
  font-size: 16px;
  font-weight: 400;
  display: block;
  margin-bottom: -0.1em;
}
.testi-box-profile .testi-box-profile-ratting {
  margin-left: auto;
  align-self: center;
  color: #fbb138;
  font-size: 14px;
  gap: 6px;
  display: flex;
}
@media (max-width: 1399px) {
  .testi-box .testi-box_text {
    font-size: 20px;
  }
}
@media (max-width: 1299px) {
  .testi-box .testi-box_text {
    font-size: 16px;
    line-height: 30px;
  }
}
@media (max-width: 991px) {
  .testi-box .testi-box_text {
    margin-top: 36px;
  }
}
@media (max-width: 575px) {
  .testi-box .testi-box_text {
    margin-top: 16px;
  }
  .testi-box .testi-box-profile {
    flex-wrap: wrap;
  }
  .testi-box .testi-box-profile .testi-box-profile-ratting {
    margin-left: 0;
  }
}

.testimonial-slider-wrap {
  position: relative;
}
.testimonial-slider-wrap .slider-arrow {
  left: var(--pos-x, -225px);
}
.testimonial-slider-wrap .slider-arrow.next-btn {
  right: var(--pos-x, -225px);
  left: auto;
}
@media (max-width: 1500px) {
  .testimonial-slider-wrap .slider-arrow {
    --pos-x: -225px;
  }
}
@media (max-width: 1399px) {
  .testimonial-slider-wrap .slider-arrow {
    --pos-x: -165px;
  }
}
@media (max-width: 1299px) {
  .testimonial-slider-wrap .slider-arrow {
    --pos-x: -125px;
  }
}
@media (max-width: 1199px) {
  .testimonial-slider-wrap .slider-arrow {
    --pos-x: -105px;
  }
}
@media (max-width: 991px) {
  .testimonial-slider-wrap .slider-arrow {
    --pos-x: -100px;
    margin-left: 0;
    margin-right: 0;
  }
}
@media (max-width: 767px) {
  .testimonial-slider-wrap .slider-arrow {
    --pos-x: -80px;
  }
}
@media (max-width: 575px) {
  .testimonial-slider-wrap .slider-arrow {
    display: none;
  }
}

.testimonial-slider1 {
  --bs-gutter-x: 150px;
}
.testimonial-slider1 .slick-track > [class*="col"] {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

/* CTA Area 2 ---------------------------------- */
.cta-2-shape1 {
  position: absolute;
  left: -150px;
  top: 300px;
  z-index: -1;
}

.cta-2-shape2 {
  position: absolute;
  background: radial-gradient(
    120.5% 120.5% at -19.95% 23.74%,
    #190f2e 47.39%,
    #7d4087 94.59%
  );
  width: 81px;
  height: 81px;
  border-radius: 50%;
  right: 25px;
  bottom: 35%;
  z-index: -1;
}

.cta-2-shape3 {
  position: absolute;
  right: 30%;
  bottom: 10px;
  background: radial-gradient(
    139.08% 139.08% at 6.17% 137.08%,
    #130f1a 47.39%,
    #3b2a5e 82.32%,
    #8543b1 99.14%
  );
  width: 81px;
  height: 81px;
  border-radius: 50%;
  filter: blur(6.66516px);
  z-index: -1;
}

.cta-2-shape2 {
  position: absolute;
  background: radial-gradient(
    120.5% 120.5% at -19.95% 23.74%,
    #190f2e 47.39%,
    #7d4087 94.59%
  );
  width: 81px;
  height: 81px;
  border-radius: 50%;
  right: 25px;
  bottom: 35%;
  z-index: -1;
}

.cta-wrap2 {
  border-radius: 30px;
  padding-left: 110px;
  padding-right: 110px;
}
@media (max-width: 1399px) {
  .cta-wrap2 {
    padding-left: 75px;
    padding-right: 75px;
  }
}
@media (max-width: 1199px) {
  .cta-wrap2 {
    padding-left: 60px;
    padding-right: 60px;
  }
}
@media (max-width: 575px) {
  .cta-wrap2 {
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 20px;
  }
}
@media (max-width: 375px) {
  .cta-wrap2 {
    padding-left: 20px;
    padding-right: 20px;
  }
}

/* CTA Area 3 ---------------------------------- */
.cta-wrap3 {
  background: linear-gradient(180deg, #e5f8c6 0%, #61b998 100%);
  border-radius: 25px;
  display: flex;
  justify-content: space-between;
  padding-right: 130px;
  margin-top: 90px;
}
.cta-wrap3 .cta-wrap-details {
  padding: 75px 100px;
  max-width: 670px;
}
.cta-wrap3 .cta-3-thumb {
  margin-top: -90px;
}
@media (max-width: 1399px) {
  .cta-wrap3 {
    margin-top: 40px;
  }
  .cta-wrap3 .cta-3-thumb {
    margin-top: -40px;
  }
  .cta-wrap3 .cta-wrap-details {
    padding: 60px 100px;
  }
}
@media (max-width: 1199px) {
  .cta-wrap3 {
    margin-top: 20px;
  }
  .cta-wrap3 .cta-wrap-details {
    padding: 60px;
  }
  .cta-wrap3 .cta-3-thumb {
    margin-top: -20px;
  }
}
@media (max-width: 991px) {
  .cta-wrap3 {
    padding-right: 100px;
    align-items: center;
    margin-top: 0;
  }
  .cta-wrap3 .cta-wrap-details {
    padding: 50px 0 50px 50px;
  }
}
@media (max-width: 767px) {
  .cta-wrap3 {
    padding: 50px 30px;
    flex-direction: column-reverse;
    gap: 40px;
    text-align: center;
  }
  .cta-wrap3 .cta-wrap-details {
    padding: 0;
  }
  .cta-wrap3 .cta-3-thumb {
    margin-top: 0;
    animation: none !important;
  }
}

.play-btn {
  display: inline-block;
  position: relative;
  z-index: 1;
}
.play-btn > i {
  display: inline-block;
  width: var(--icon-size, 110px);
  height: var(--icon-size, 110px);
  line-height: 107px;
  text-align: center;
  background: linear-gradient(
    93.17deg,
    var(--tg-primary-color) -18.55%,
    #7e2afd 163.09%
  );
  color: var(--tg-primary-color);
  font-size: var(--icon-font-size, 30px);
  border-radius: 50%;
  z-index: 1;
  transition: all ease 0.4s;
}
.play-btn:after,
.play-btn:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(223, 134, 170, 0.2);
  border: 0;
  z-index: -1;
  border-radius: 50%;
  transition: all ease 0.4s;
  animation-duration: 5s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;
  animation-name: ripple;
}
.play-btn:after {
  animation-delay: 2s;
}
.play-btn:hover i {
  background-color: var(--tg-primary-color);
  color: var(--tg-white);
}
.play-btn.style2:before,
.play-btn.style2:after {
  background-color: transparent;
  border: 1px solid var(--tg-white);
}
.play-btn.style3 > i {
  background-color: var(--tg-primary-color);
  color: var(--tg-white);
  font-size: 20px;
}
.play-btn.style3:before,
.play-btn.style3:after {
  background-color: var(--tg-white);
}
.play-btn.style3:hover > i {
  background-color: var(--tg-white);
  color: var(--tg-primary-color);
}
/*******Magnific Image*******/
.mfp-zoom-in .mfp-content {
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
}

.mfp-zoom-in.mfp-bg {
  opacity: 0;
  -webkit-transition: all 0.4s ease;
  -moz-transition: all 0.4s ease;
  -o-transition: all 0.4s ease;
  transition: all 0.4s ease;
}

.mfp-zoom-in.mfp-ready .mfp-content {
  opacity: 1;
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
}

.mfp-zoom-in.mfp-ready.mfp-bg {
  opacity: 0.7;
}

.mfp-zoom-in.mfp-removing .mfp-content {
  -webkit-transform: scale(0.7);
  -ms-transform: scale(0.7);
  transform: scale(0.7);
  opacity: 0;
}

.mfp-zoom-in.mfp-removing.mfp-bg {
  opacity: 0;
}

/* Faq 2 ---------------------------------- */
.accordion-card.style2 {
  border: 1px solid rgba(255, 255, 255, 0.3);
  background: linear-gradient(180deg, #e5f8c6 0%, #61b998 100%);
  border-radius: 63px;
  padding: 0;
  transition: 0.4s;
}
.accordion-card.style2 .accordion-button {
  font-size: 24px;
  padding: 35px 60px 35px 90px;
  margin: 0 0;
  color: var(--tg-secondary-color);
}
.accordion-card.style2 .accordion-button:after {
  content: "\f107";
  top: 56px;
  right: 36px;
  font-size: 24px;
  color: var(--tg-secondary-color);
  width: 65px;
  height: 65px;
  line-height: 67px;
  border-radius: 50%;
  background: var(--tg-white);
  transform: rotate(180deg);
  z-index: 1;
}
.accordion-card.style2 .accordion-button.collapsed {
  color: var(--tg-white);
}
.accordion-card.style2 .accordion-button.collapsed:after {
  transform: rotate(0deg);
  background: transparent;
  color: var(--tg-body-font-color);
  top: 25px;
}
.accordion-card.style2:not(:last-child) {
  margin-bottom: 20px;
}
.accordion-card.style2 .accordion-body {
  margin: 0;
}
.accordion-card.style2 .accordion-body p {
  margin: 0 200px 33px 90px;
  color: var(--tg-secondary-color);
}
.accordion-card.style2:has(.accordion-button.collapsed) {
  background: transparent;
}
@media (max-width: 991px) {
  .accordion-card.style2 .accordion-button {
    font-size: 20px;
    padding: 31px 60px 29px 60px;
  }
  .accordion-card.style2 .accordion-body p {
    margin: 0 170px 33px 60px;
  }
  .accordion-card.style2 .accordion-button.collapsed:after {
    top: 7px;
  }
}
@media (max-width: 767px) {
  .accordion-card.style2 .accordion-body p {
    margin: 0 100px 33px 40px;
  }
  .accordion-card.style2 .accordion-button {
    padding: 31px 40px 29px 40px;
  }
  .accordion-card.style2 .accordion-button:after {
    top: 19px;
    width: 50px;
    height: 50px;
    line-height: 53px;
  }
  .accordion-card.style2 .accordion-button.collapsed:after {
    top: 15px;
  }
}
@media (max-width: 575px) {
  .accordion-card.style2 .accordion-button {
    padding: 31px 70px 29px 30px;
    font-size: 17px;
  }
  .accordion-card.style2 .accordion-button:after {
    right: 15px;
  }
  .accordion-card.style2 .accordion-body p {
    margin: 0 30px 30px 30px;
  }
}

.faq-2-shape-1 {
  position: absolute;
  height: 960px;
  width: 722px;
  border-radius: 50%;
  transform: skew(40deg, 0deg);
  z-index: -1;
  background: radial-gradient(
    48.69% 39.15% at 50.02% 50.04%,
    #cd22f8 0.01%,
    rgba(200, 9, 249, 0.3) 53.96%,
    rgba(66, 0, 83, 0) 100%
  );
  right: -620px;
  bottom: -260px;
}

/*footer 2************/
.widget-about .footer-logo {
  margin-bottom: 30px;
}
.widget-about .about-text {
  font-size: 16px;
  margin-bottom: 38px;
  max-width: 390px;
  /* Medium Large devices */
}
@media (max-width: 1399px) {
  .widget-about .about-text {
    max-width: 360px;
  }
}

.widget-contact {
  max-width: 430px;
  border-left: 1px solid rgba(255, 255, 255, 0.3);
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  padding: 60px 60px 0;
  text-align: center;
  /* Medium Large devices */
  /* Large devices */
}
.widget-contact .contact-info-text {
  font-size: 16px;
  color: var(--tg-body-font-color);
  margin-bottom: 0;
}
.widget-contact .contact-info-link a {
  color: var(--tg-white);
}
.widget-contact .contact-info-link a:hover {
  color: var(--tg-primary-color);
}
.widget-contact .copyright-text {
  margin-top: 125px;
}
@media (max-width: 1399px) {
  .widget-contact {
    padding: 0px 35px;
  }
  .widget-contact .copyright-text {
    margin-top: 85px;
  }
}
@media (max-width: 1199px) {
  .widget-contact {
    border: 0;
    padding: 0;
    text-align: left;
  }
  .widget-contact .copyright-text {
    margin-top: 55px;
  }
}

.widget-newsletter {
  max-width: 330px;
  margin-top: 60px;
}
.widget-newsletter .newsletter-text {
  margin-top: -0.5em;
}
@media (max-width: 1399px) {
  .widget-newsletter {
    margin-top: 0;
  }
}
.newsletter-form {
  position: relative;
  margin-top: 20px;
}
.newsletter-form .form-group {
  margin-bottom: 0px;
}
.newsletter-form .form-group input {
  height: 60px;
  padding: 0 145px 0 30px;
  background: var(--tg-white);
  border: 0;
  border-radius: 50px;
  font-size: 16px;
  font-family: var(--tg-heading-font-family);
  font-weight: 400;
  letter-spacing: -0.48px;
}
.newsletter-form .form-group input::placeholder {
  color: rgba(10, 12, 0, 0.5);
}
.newsletter-form .btn {
  position: absolute;
  right: 10px;
  top: 4px;
  padding: 0 30px;
  font-size: 16px;
  font-weight: 600;
  line-height: 52px;
}
.brand-item img {
  max-width: 140px;
}

/* Slider */
.slick-slider {
  position: relative;

  display: block;
  box-sizing: border-box;

  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  -webkit-touch-callout: none;
  -khtml-user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;

  display: block;
  overflow: hidden;

  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  top: 0;
  left: 0;

  display: block;
  margin-left: auto;
  margin-right: auto;
}
.slick-track:before,
.slick-track:after {
  display: table;

  content: "";
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  display: none;
  float: left;

  height: 100%;
  min-height: 1px;
}
[dir="rtl"] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;

  height: auto;

  border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
  display: none;
}

/* Deafult Margin & Padding */
/*-- Margin Top --*/
.mt-5 {
  margin-top: 5px;
}
.mt-10 {
  margin-top: 10px;
}
.mt-15 {
  margin-top: 15px;
}
.mt-20 {
  margin-top: 20px;
}
.mt-25 {
  margin-top: 25px;
}
.mt-30 {
  margin-top: 30px;
}
.mt-35 {
  margin-top: 35px;
}
.mt-40 {
  margin-top: 40px;
}
.mt-45 {
  margin-top: 45px;
}
.mt-50 {
  margin-top: 50px;
}
.mt-55 {
  margin-top: 55px;
}
.mt-60 {
  margin-top: 60px;
}
.mt-65 {
  margin-top: 65px;
}
.mt-70 {
  margin-top: 70px;
}
.mt-75 {
  margin-top: 75px;
}
.mt-80 {
  margin-top: 80px;
}
.mt-85 {
  margin-top: 85px;
}
.mt-90 {
  margin-top: 90px;
}
.mt-95 {
  margin-top: 95px;
}
.mt-100 {
  margin-top: 100px;
}
.mt-105 {
  margin-top: 105px;
}
.mt-110 {
  margin-top: 110px;
}
.mt-115 {
  margin-top: 115px;
}
.mt-120 {
  margin-top: 120px;
}
.mt-125 {
  margin-top: 125px;
}
.mt-130 {
  margin-top: 130px;
}
.mt-135 {
  margin-top: 135px;
}
.mt-140 {
  margin-top: 140px;
}
.mt-145 {
  margin-top: 145px;
}
.mt-150 {
  margin-top: 150px;
}
.mt-155 {
  margin-top: 155px;
}
.mt-160 {
  margin-top: 160px;
}
.mt-165 {
  margin-top: 165px;
}
.mt-170 {
  margin-top: 170px;
}
.mt-175 {
  margin-top: 175px;
}
.mt-180 {
  margin-top: 180px;
}
.mt-185 {
  margin-top: 185px;
}
.mt-190 {
  margin-top: 190px;
}
.mt-195 {
  margin-top: 195px;
}
.mt-200 {
  margin-top: 200px;
}
/*-- Margin Bottom --*/

.mb-5 {
  margin-bottom: 5px;
}
.mb-10 {
  margin-bottom: 10px;
}
.mb-15 {
  margin-bottom: 15px;
}
.mb-20 {
  margin-bottom: 20px;
}
.mb-25 {
  margin-bottom: 25px;
}
.mb-30 {
  margin-bottom: 30px;
}
.mb-35 {
  margin-bottom: 35px;
}
.mb-40 {
  margin-bottom: 40px;
}
.mb-45 {
  margin-bottom: 45px;
}
.mb-50 {
  margin-bottom: 50px;
}
.mb-55 {
  margin-bottom: 55px;
}
.mb-60 {
  margin-bottom: 60px;
}
.mb-65 {
  margin-bottom: 65px;
}
.mb-70 {
  margin-bottom: 70px;
}
.mb-75 {
  margin-bottom: 75px;
}
.mb-80 {
  margin-bottom: 80px;
}
.mb-85 {
  margin-bottom: 85px;
}
.mb-90 {
  margin-bottom: 90px;
}
.mb-95 {
  margin-bottom: 95px;
}
.mb-100 {
  margin-bottom: 100px;
}
.mb-105 {
  margin-bottom: 105px;
}
.mb-110 {
  margin-bottom: 110px;
}
.mb-115 {
  margin-bottom: 115px;
}
.mb-120 {
  margin-bottom: 120px;
}
.mb-125 {
  margin-bottom: 125px;
}
.mb-130 {
  margin-bottom: 130px;
}
.mb-135 {
  margin-bottom: 135px;
}
.mb-140 {
  margin-bottom: 140px;
}
.mb-145 {
  margin-bottom: 145px;
}
.mb-150 {
  margin-bottom: 150px;
}
.mb-155 {
  margin-bottom: 155px;
}
.mb-160 {
  margin-bottom: 160px;
}
.mb-165 {
  margin-bottom: 165px;
}
.mb-170 {
  margin-bottom: 170px;
}
.mb-175 {
  margin-bottom: 175px;
}
.mb-180 {
  margin-bottom: 180px;
}
.mb-185 {
  margin-bottom: 185px;
}
.mb-190 {
  margin-bottom: 190px;
}
.mb-195 {
  margin-bottom: 195px;
}
.mb-200 {
  margin-bottom: 200px;
}
/*-- Padding Top --*/

.pt-5 {
  padding-top: 5px;
}
.pt-10 {
  padding-top: 10px;
}
.pt-15 {
  padding-top: 15px;
}
.pt-20 {
  padding-top: 20px;
}
.pt-25 {
  padding-top: 25px;
}
.pt-30 {
  padding-top: 30px;
}
.pt-35 {
  padding-top: 35px;
}
.pt-40 {
  padding-top: 40px;
}
.pt-45 {
  padding-top: 45px;
}
.pt-50 {
  padding-top: 50px;
}
.pt-55 {
  padding-top: 55px;
}
.pt-60 {
  padding-top: 60px;
}
.pt-65 {
  padding-top: 65px;
}
.pt-70 {
  padding-top: 70px;
}
.pt-75 {
  padding-top: 75px;
}
.pt-80 {
  padding-top: 80px;
}
.pt-85 {
  padding-top: 85px;
}
.pt-90 {
  padding-top: 90px;
}
.pt-95 {
  padding-top: 95px;
}
.pt-100 {
  padding-top: 100px;
}
.pt-105 {
  padding-top: 105px;
}
.pt-110 {
  padding-top: 110px;
}
.pt-115 {
  padding-top: 115px;
}
.pt-120 {
  padding-top: 120px;
}
.pt-125 {
  padding-top: 125px;
}
.pt-130 {
  padding-top: 130px;
}
.pt-135 {
  padding-top: 135px;
}
.pt-140 {
  padding-top: 140px;
}
.pt-145 {
  padding-top: 145px;
}
.pt-150 {
  padding-top: 150px;
}
.pt-155 {
  padding-top: 155px;
}
.pt-160 {
  padding-top: 160px;
}
.pt-165 {
  padding-top: 165px;
}
.pt-170 {
  padding-top: 170px;
}
.pt-175 {
  padding-top: 175px;
}
.pt-180 {
  padding-top: 180px;
}
.pt-185 {
  padding-top: 185px;
}
.pt-190 {
  padding-top: 190px;
}
.pt-195 {
  padding-top: 195px;
}
.pt-200 {
  padding-top: 200px;
}
/*-- Padding Bottom --*/

.pb-5 {
  padding-bottom: 5px;
}
.pb-10 {
  padding-bottom: 10px;
}
.pb-15 {
  padding-bottom: 15px;
}
.pb-20 {
  padding-bottom: 20px;
}
.pb-25 {
  padding-bottom: 25px;
}
.pb-30 {
  padding-bottom: 30px;
}
.pb-35 {
  padding-bottom: 35px;
}
.pb-40 {
  padding-bottom: 40px;
}
.pb-45 {
  padding-bottom: 45px;
}
.pb-50 {
  padding-bottom: 50px;
}
.pb-55 {
  padding-bottom: 55px;
}
.pb-60 {
  padding-bottom: 60px;
}
.pb-65 {
  padding-bottom: 65px;
}
.pb-70 {
  padding-bottom: 70px;
}
.pb-75 {
  padding-bottom: 75px;
}
.pb-80 {
  padding-bottom: 80px;
}
.pb-85 {
  padding-bottom: 85px;
}
.pb-90 {
  padding-bottom: 90px;
}
.pb-95 {
  padding-bottom: 95px;
}
.pb-100 {
  padding-bottom: 100px;
}
.pb-105 {
  padding-bottom: 105px;
}
.pb-110 {
  padding-bottom: 110px;
}
.pb-115 {
  padding-bottom: 115px;
}
.pb-120 {
  padding-bottom: 120px;
}
.pb-125 {
  padding-bottom: 125px;
}
.pb-130 {
  padding-bottom: 130px;
}
.pb-135 {
  padding-bottom: 135px;
}
.pb-140 {
  padding-bottom: 140px;
}
.pb-145 {
  padding-bottom: 145px;
}
.pb-150 {
  padding-bottom: 150px;
}
.pb-155 {
  padding-bottom: 155px;
}
.pb-160 {
  padding-bottom: 160px;
}
.pb-165 {
  padding-bottom: 165px;
}
.pb-170 {
  padding-bottom: 170px;
}
.pb-175 {
  padding-bottom: 175px;
}
.pb-180 {
  padding-bottom: 180px;
}
.pb-185 {
  padding-bottom: 185px;
}
.pb-190 {
  padding-bottom: 190px;
}
.pb-195 {
  padding-bottom: 195px;
}
.pb-200 {
  padding-bottom: 200px;
}

/*-- Padding Left --*/
.pl-0 {
  padding-left: 0px;
}
.pl-5 {
  padding-left: 5px;
}
.pl-10 {
  padding-left: 10px;
}
.pl-15 {
  padding-left: 15px;
}
.pl-20 {
  padding-left: 20px;
}
.pl-25 {
  padding-left: 35px;
}
.pl-30 {
  padding-left: 30px;
}
.pl-35 {
  padding-left: 35px;
}

.pl-35 {
  padding-left: 35px;
}

.pl-40 {
  padding-left: 40px;
}

.pl-45 {
  padding-left: 45px;
}

.pl-50 {
  padding-left: 50px;
}

.pl-55 {
  padding-left: 55px;
}

.pl-60 {
  padding-left: 60px;
}
.pl-65 {
  padding-left: 65px;
}
.pl-70 {
  padding-left: 70px;
}
.pl-75 {
  padding-left: 75px;
}
.pl-80 {
  padding-left: 80px;
}
.pl-85 {
  padding-left: 80px;
}
.pl-90 {
  padding-left: 90px;
}
.pl-95 {
  padding-left: 95px;
}
.pl-100 {
  padding-left: 100px;
}

/*-- Padding Right --*/
.pr-0 {
  padding-right: 0px;
}
.pr-5 {
  padding-right: 5px;
}
.pr-10 {
  padding-right: 10px;
}
.pr-15 {
  padding-right: 15px;
}
.pr-20 {
  padding-right: 20px;
}
.pr-25 {
  padding-right: 35px;
}
.pr-30 {
  padding-right: 30px;
}
.pr-35 {
  padding-right: 35px;
}

.pr-35 {
  padding-right: 35px;
}

.pr-40 {
  padding-right: 40px;
}

.pr-45 {
  padding-right: 45px;
}

.pr-50 {
  padding-right: 50px;
}

.pr-55 {
  padding-right: 55px;
}

.pr-60 {
  padding-right: 60px;
}
.pr-65 {
  padding-right: 65px;
}
.pr-70 {
  padding-right: 70px;
}
.pr-75 {
  padding-right: 75px;
}
.pr-80 {
  padding-right: 80px;
}
.pr-85 {
  padding-right: 80px;
}
.pr-90 {
  padding-right: 90px;
}
.pr-95 {
  padding-right: 95px;
}
.pr-100 {
  padding-right: 100px;
}

/* font weight */
.f-700 {
  font-weight: 700;
}
.f-600 {
  font-weight: 600;
}
.f-500 {
  font-weight: 500;
}
.f-400 {
  font-weight: 400;
}
.f-300 {
  font-weight: 300;
}

/* Background Color */

.gray-bg {
  background: #f7f7fd;
}
.white-bg {
  background: #fff;
}
.black-bg {
  background: #222;
}
.theme-bg {
  background: #222;
}
.primary-bg {
  background: #222;
}
/* Color */

.white-color {
  color: #fff;
}
.black-color {
  color: #222;
}
.theme-color {
  color: #222;
}
.primary-color {
  color: #222;
}
/* black overlay */

[data-overlay] {
  position: relative;
}
[data-overlay]::before {
  background: #000 none repeat scroll 0 0;
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 1;
}
[data-overlay="3"]::before {
  opacity: 0.3;
}
[data-overlay="4"]::before {
  opacity: 0.4;
}
[data-overlay="5"]::before {
  opacity: 0.5;
}
[data-overlay="6"]::before {
  opacity: 0.6;
}
[data-overlay="7"]::before {
  opacity: 0.7;
}
[data-overlay="8"]::before {
  opacity: 0.8;
}
[data-overlay="9"]::before {
  opacity: 0.9;
}

.slider-area {
  /* position: relative; */
}
.slider-arrow {
  display: inline-block;
  padding: 0;
  background-color: var(--tg-white);
  border: 1px solid #ebedf1;
  color: var(--tg-secondary-color);
  position: absolute;
  top: 50%;
  left: var(--pos-x, -120px);
  width: var(--icon-size, 55px);
  height: var(--icon-size, 55px);
  line-height: var(--icon-size, 55px);
  font-size: var(--icon-font-size, 18px);
  margin-top: calc(var(--icon-size, 55px) / -2);
  z-index: 3;
  border-radius: 50%;
}
.slider-arrow.next-btn {
  right: var(--pos-x, -120px);
  left: auto;
}
.slider-arrow:hover {
  background-color: var(--tg-primary-color);
  color: var(--tg-white);
  border-color: var(--tg-primary-color);
}
.slider-arrow.default {
  position: relative;
  --pos-x: 0;
  margin-top: 0;
}

.slider-area .slick-arrow.slick-prev {
  right: 0;
}

.slider-area .slick-arrow.slick-next {
  right: var(--pos-x, -120px);
}

.slider-area .slick-arrow {
  display: inline-block;
  padding: 0;
  background-color: var(--tg-white);
  border: 1px solid #ebedf1;
  color: var(--tg-secondary-color);
  position: absolute;
  top: -55px;
  /* left: var(--pos-x, -120px); */
  width: var(--icon-size, 55px);
  height: var(--icon-size, 55px);
  line-height: var(--icon-size, 55px);
  font-size: var(--icon-font-size, 18px);
  margin-top: calc(var(--icon-size, 55px) / -2);
  z-index: 3;
  border-radius: 50%;
}
.slider-area .slick-arrow:hover {
  background-color: var(--tg-primary-color);
  color: var(--tg-white);
  border-color: var(--tg-primary-color);
}

button.slick-arrow.slick-prev {
  position: absolute;
  right: 34% !important;
  left: unset ;
}

.slider-area .slick-arrow.slick-next {
  right: 30%;
}

/* Extra large devices (large desktops, 1800px and up) */
@media (max-width: 1800px) {
  .custom-container {
    max-width: 1550px;
  }
  .contribution-shape-wrap img:nth-child(2) {
    right: 8%;
    bottom: 14%;
  }
  .roadmap-content {
    padding: 75px 40px 65px;
  }
}

/* Extra large devices (large desktops, 1800px and up) */
@media (max-width: 1500px) {
  .container {
    max-width: 1320px;
  }
  .custom-container {
    max-width: 1330px;
  }
  .navbar-wrap > ul > li {
    margin-right: 45px;
  }
  .menu-area {
    padding: 20px 0 20px;
  }
  .banner-content .title {
    font-size: 55px;
    margin-bottom: 25px;
  }
  .banner-content p {
    margin-bottom: 40px;
  }
  .banner-scroll-down {
    bottom: 40px;
  }
  .contribution-title .title {
    font-size: 50px;
  }
  .contribution-shape-wrap img:nth-child(1) {
    left: 2%;
    bottom: 0%;
  }
  .chart-inner-wrap .right-side-content {
    margin-left: 160px;
  }
  .roadmap-content .title {
    font-size: 25px;
  }
  .document-shape img {
    top: -8%;
  }
  .banner-shape-wrap img:nth-child(1) {
    left: 3%;
    bottom: 10%;
  }
  .banner-shape-wrap img:nth-child(2) {
    right: 3%;
    bottom: 12%;
  }
  .roadmap-title {
    margin-left: 5px;
  }
  .breadcrumb-area {
    padding: 200px 0 130px;
  }
}

/* Extra large devices (large desktops, 1200px and up) */
@media (max-width: 1199.98px) {
  .container,
  .custom-container {
    max-width: 960px;
  }
  .header-action > ul li.header-login {
    display: none;
  }
  .navbar-wrap > ul > li {
    margin-right: 35px;
  }
  .navbar-wrap ul {
    padding: 0 30px;
  }
  .header-action > ul li {
    margin-left: 0;
  }
  .banner-content .title {
    font-size: 50px;
  }
  .banner-shape-wrap img:nth-child(2) {
    right: 1%;
    bottom: 17%;
    width: 170px;
  }
  .banner-shape-wrap img:nth-child(1) {
    left: 1%;
    bottom: 33%;
    width: 175px;
  }
  .contribution-title .title {
    font-size: 45px;
  }
  .contribution-shape-wrap img:nth-child(2) {
    right: 8%;
    bottom: 9%;
  }
  .features-item {
    padding: 40px 30px 40px 30px;
  }
  .features-item-two {
    padding: 25px 15px;
  }
  .features-img-two {
    margin-right: 15px;
  }
  .features-content-two .title {
    font-size: 20px;
  }
  .section-title-two.section-title .title {
    font-size: 50px;
  }
  .section-title .title {
    font-size: 45px;
  }
  .chart-wrap {
    margin-left: 0;
  }
  .chart-wrap .chart {
    margin: 0 0 50px 30px;
  }
  .chart-inner-wrap .right-side-content {
    margin-left: 30px;
  }
  .roadmap-content {
    padding: 75px 30px 40px;
  }
  .roadmap-title {
    margin-left: 0;
  }
  .download-inner-wrap {
    padding: 60px 30px 0;
  }
  .download-btn-wrap .download-btn img {
    max-height: 50px;
  }
  .document-form-wrap {
    padding: 50px 30px 40px;
  }
  .document-wrap {
    padding: 50px 25px 40px;
  }
  .document-wrap .list-wrap a .icon {
    width: 110px;
  }
  .blog-masonry-content p {
    margin-bottom: 25px;
    width: 100%;
  }
  .blog-meta .list-wrap {
    gap: 15px 30px;
    margin-bottom: 20px;
  }
  .blog-masonry-post {
    padding: 20px 20px 20px 20px;
  }
  .blog-masonry-content .title {
    margin-bottom: 20px;
    font-size: 22px;
    width: 100%;
  }
  .blog-masonry-thumb {
    width: 270px;
    margin-right: 15px;
  }
  .blog-masonry-content .content-bottom {
    gap: 20px;
  }
  .read-more-btn {
    margin-left: 0;
  }
  .blog-standard-thumb img {
    min-height: 300px;
    object-fit: cover;
  }
  .blog-standard-content {
    padding: 0 0px 0 20px;
  }
  .blog-standard-content .title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  .blog-standard-content p {
    margin-bottom: 30px;
  }
  .blog-widget {
    padding: 35px 20px 40px;
  }
  .breadcrumb-content .title {
    font-size: 55px;
  }
  .blog-details-thumb img {
    height: 340px;
  }
  .blog-details-content .title {
    font-size: 24px;
  }
  .blog-details-content blockquote {
    padding: 30px 25px;
  }
  .blog-details-content blockquote p {
    font-size: 22px;
  }
  .blog-details-inner-img img {
    height: 250px;
    width: 100%;
  }
  .latest-comments .children {
    margin: 0 0 0 60px;
  }
  .comment-respond {
    padding: 40px 30px 45px;
  }
  .contact-form {
    padding: 50px 30px;
  }
}

/* Large devices (desktops, 992px and up) */
@media (max-width: 991.98px) {
  .container,
  .custom-container {
    max-width: 720px;
  }
  .menu-area {
    padding: 25px 0;
  }
  .menu-nav {
    justify-content: space-between;
  }
  .header-action {
    margin-right: 40px;
  }
  .menu-outer .navbar-wrap {
    display: block !important;
  }
  .menu-area .mobile-nav-toggler {
    display: flex;
  }
  .banner-content .title {
    font-size: 45px;
  }
  .header-action {
    display: none;
  }
  .banner-shape-wrap {
    display: none;
  }
  .contribution-shape-wrap img:nth-child(2) {
    right: 14%;
    bottom: -1%;
  }
  .contribution-shape-wrap img:nth-child(1) {
    left: 0%;
    bottom: 0%;
    width: 150px;
  }
  .progress-wrap .list-wrap {
    padding: 0 100px;
  }
  .section-title .title {
    font-size: 40px;
  }
  .features-item {
    padding: 40px 40px 40px 40px;
  }
  .section-title-two.section-title .title {
    font-size: 45px;
  }
  .features-shape-wrap img:nth-child(2) {
    display: none;
  }
  .chart-wrap .chart {
    margin: 0 auto 30px;
  }
  .chart-tab {
    text-align: center;
  }
  .chart-tab .tab-content {
    text-align: left;
  }
  .chart-inner-wrap .right-side-content {
    margin-left: 0;
    text-align: center;
  }
  .chart-inner-wrap .right-side-content p {
    text-align: left;
  }
  .chart-inner-wrap .right-side-content p br {
    display: none;
  }
  .chart-wrap {
    margin-bottom: 50px;
  }
  .chart-inner-wrap::before {
    display: none;
  }
  .faq-wrap {
    margin: 0 0;
  }
  .faq-wrap .accordion-button {
    padding: 38px 80px 38px 30px;
  }
  .faq-wrap .accordion-body {
    padding: 0px 30px 60px;
  }
  .faq-wrap .accordion-button::after {
    right: 30px;
  }
  .download-content {
    text-align: center;
  }
  .download-list .list-wrap {
    justify-content: center;
  }
  .download-btn-wrap {
    justify-content: center;
  }
  .download-img {
    text-align: center;
  }
  .download-img img:nth-child(2) {
    right: 18%;
  }
  .document-form-wrap {
    height: auto;
    margin-bottom: 50px;
  }
  .footer-widget .fw-title {
    margin-bottom: 15px;
  }
  .footer-newsletter p {
    margin-bottom: 20px;
  }
  .footer-shape-wrap img:nth-child(2) {
    display: none;
  }
  .container-fluid {
    padding: 0 !important;
  }
  .blog-masonry-thumb {
    margin-right: 25px;
  }
  .read-more-btn {
    margin-left: auto;
  }
  .blog-sidebar {
    margin-top: 120px;
  }
  .blog-widget {
    padding: 45px 40px 50px;
  }
  .contact-form-wrap .col-57 {
    width: 100%;
  }
  .contact-form-wrap .col-43 {
    width: 100%;
  }
  .contact-form {
    padding: 50px 40px;
    border-radius: 15px 15px 0 0;
  }
  .contact-map {
    height: 400px;
  }
  .contact-map iframe {
    border-radius: 0 0 15px 15px;
  }
}

/* Medium devices (tablets, 768px and up) */
@media (max-width: 767.98px) {
  .container,
  .custom-container {
    max-width: 100%;
  }
  .transparent-header {
    top: 0;
  }
  .chart-wrap .chart {
    width: 320px;
    height: 320px;
  }
  .contribution-shape-wrap {
    display: none;
  }
  .container-fluid {
    padding: 0 !important;
  }
  .progress-wrap .list-wrap {
    padding: 0 0;
  }
  .chart-inner-wrap .right-side-content .list-wrap li {
    text-align: left;
  }
  .banner-bg {
    left: 0;
    right: 0;
  }
  .time-count::after,
  .time-count::before {
    display: none;
  }
  .progress-wrap .progress-title {
    font-size: 16px;
  }
  .section-title .title {
    font-size: 36px;
  }
  .features-item {
    padding: 30px;
    display: flex;
    flex-direction: column-reverse;
  }
  .features-content {
    width: 100%;
    margin-right: 0;
  }
  .features-img {
    text-align: center;
    margin-bottom: 30px;
  }
  .features-bg {
    left: 0;
    right: 0;
  }
  .section-title-two.section-title .title {
    font-size: 38px;
  }
  .chart-list .list-wrap li {
    width: 100%;
  }
  .faq-wrap .accordion-button {
    font-size: 22px;
  }
  .faq-wrap .accordion-button::after {
    top: 35px;
  }
  .download-img img:nth-child(2) {
    right: 3%;
  }
  .footer-bg {
    left: 0;
    right: 0;
  }
  .footer-shape-wrap {
    display: none;
  }
  .contribution-title .title {
    font-size: 40px;
  }
  .contribution-area {
    padding: 100px 0;
  }
  .features-area {
    padding: 100px 0 70px;
  }
  .features-inner-wrap {
    padding: 100px 0;
  }
  .features-item-wrap {
    margin-bottom: 60px;
  }
  .chart-area {
    padding: 100px 0 0;
  }
  .roadmap-area {
    padding: 100px 0 0;
  }
  .team-area {
    padding: 100px 0;
  }
  .faq-inner-wrap {
    padding: 100px 0;
  }
  .download-area {
    padding: 100px 0;
  }
  .download-content {
    padding: 0 0 100px;
  }
  .footer-top {
    padding: 100px 0 30px;
  }
  .banner-content p br {
    display: none;
  }
  .blog-masonry-post {
    display: block;
  }
  .blog-masonry-thumb {
    width: 100%;
    margin: 0 0 25px 0;
  }
  .read-more-btn {
    margin-left: 0;
  }
  .blog-masonry-content .content-bottom {
    justify-content: space-between;
  }
  .blog-standard-content {
    padding: 0;
  }
  .blog-standard-content .title {
    font-size: 20px;
  }
  .blog-standard-post {
    padding: 20px 20px 40px 20px;
  }
  .blog-widget {
    padding: 30px 20px 35px;
  }
  .blog-sidebar {
    margin-top: 100px;
  }
  .blog-area {
    padding: 100px 0;
  }
  .document-shape img {
    display: none;
  }
  .breadcrumb-content .title {
    font-size: 40px;
  }
  .breadcrumb-area {
    padding: 170px 0 100px;
  }
  .breadcrumb-bg {
    left: 0;
    right: 0;
  }
  .breadcrumb-shape-wrap img:nth-child(1) {
    display: none;
  }
  .breadcrumb-shape-wrap img:nth-child(2) {
    right: 5%;
    bottom: 12%;
  }
  .blog-masonry-thumb img {
    height: auto;
    width: 100%;
  }
  .document-form-wrap {
    padding: 50px 20px 40px;
  }
  .document-wrap {
    padding: 50px 20px 40px;
  }
  .blog-details-thumb img {
    height: 300px;
    width: 100%;
  }
  .blog-details-content blockquote p {
    font-size: 20px;
  }
  .blog-post-share .list-wrap {
    justify-content: flex-start;
    margin-top: 25px;
  }
  .blog-details-bottom {
    margin-top: 40px;
    padding-bottom: 40px;
  }
  .blog-avatar-wrap {
    display: block;
    padding: 30px 25px;
  }
  .blog-avatar-img {
    margin: 0 0 25px 0;
  }
  .comments-wrap-title {
    font-size: 24px;
  }
  .comments-box {
    display: block;
  }
  .comments-avatar {
    margin: 0 0 20px 0;
  }
  .latest-comments .children {
    margin: 0;
  }
  .comment-reply-title {
    font-size: 24px;
  }
  .comment-respond {
    padding: 40px 20px 45px;
  }
  .comment-form .checkbox-grp {
    margin: 30px 0;
    align-items: flex-start;
  }
  .blog-details-area {
    padding: 100px 0;
  }
  .contact-form {
    padding: 40px 20px;
    border-radius: 15px 15px 0 0;
  }
  .contact-map {
    height: 320px;
  }
  .contact-form .title {
    font-size: 24px;
  }
  .contact-area {
    padding: 100px 0;
  }
  .contact-info-wrap {
    margin-bottom: 70px;
  }
  .scroll-top {
    right: 15px;
  }
  .breadcrumb-bg {
    margin: 0;
  }
  .footer-bg {
    margin: -350px 0 0;
  }
  .banner-bg {
    margin: 0;
  }
  .features-bg {
    margin: 0;
  }
  .team-bg {
    margin: 0;
  }
}

/* Small devices (landscape phones, 576px and up) */
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .container,
  .custom-container {
    max-width: 540px;
  }
  .chart-wrap .chart {
    width: 340px;
    height: 340px;
  }
  .banner-content .title {
    font-size: 50px;
  }
  .coming-time {
    gap: 20px 20px;
  }
  .blog-masonry-thumb img {
    height: 300px;
  }
  .blog-masonry-content .title {
    font-size: 24px;
  }
  .blog-standard-content .title {
    font-size: 24px;
  }
  .blog-widget {
    padding: 45px 30px 50px;
  }
  .document-form-wrap {
    padding: 50px 30px 40px;
  }
  .document-wrap {
    padding: 50px 30px 40px;
  }
  .comment-respond {
    padding: 40px 30px 45px;
  }
  .blog-details-content blockquote p {
    font-size: 22px;
  }
  .contact-form {
    padding: 50px 30px;
  }
}

.roadMap-active2 .slick-next:before,
.roadMap-active2 .slick-prev::before {
  content: none !important;
}

.roadMap-active2 .slider-area .slick-arrow.slick-next {
  right: 33%;
}

.roadMap-active2 .slider-area .slick-arrow.slick-prev {
  left: 68%;
}

.rvc-utility-app {
  min-height: 100vh;
  height: 100%;
}

.blockChainbtn {
  user-select: none;
  color: var(--tg-black);
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  font-weight: 700;
  letter-spacing: -0.42px;
  line-height: 1;
  margin-bottom: 0px;
  text-align: center;
  text-transform: none;
  touch-action: manipulation;
  vertical-align: middle;
  background: var(--tg-white) none repeat scroll 0 0;
  border-width: medium;
  border-style: none;
  border-color: initial;
  border-image: initial;
  border-radius: 30px;
  padding: 18px 45px;
  transition: 0.4s;
  white-space: nowrap;
  position: relative;
}

.blockChainbtn::after {
  content: "";
  position: absolute;
  top: 0px;
  left: 0px;
  width: 101%;
  height: 100%;
  z-index: -1;
  opacity: 0;
  border-radius: inherit;
  background: linear-gradient(
    82.3deg,
    var(--tg-primary-color) 10.8%,
    var(--tg-theme-color2) 94.3%
  );
  transition: 0.475s;
}

/* .dropdown-menu {
  width: fit-content;
  min-width: auto;
  top: calc(100% + 10px) !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  padding: 8px 20px !important;
  border-color: #e3e3e3;
}
.dropdown-menu a {
  color: var(--tg-secondary-color) !important;
}
.dropdown-menu a:hover {
  color: var(--tg-primary-color) !important;
}
.dropdown-menu:before {
  content: "";
  position: absolute;
  left: 50%;
  top: -7px;
  width: 14px;
  height: 14px;
  margin-left: -7px;
  background-color: var(--tg-white);
  z-index: -1;
  transform: rotate(45deg);
  border-top: 1px solid #e3e3e3;
  border-left: 1px solid #e3e3e3;
} */

a.mobileAuthLink {
  background: #fff;
  padding: 0.5rem 2rem;
  border-radius: 3rem;
  color: #000;
  font-weight: 600;
  text-align: center;
}

@media (width < 576px) {
  .slider-area .slick-arrow.slick-next {
    right: unset;
    left: 6rem;
    top: -1rem !important;
  }

  button.slick-arrow.slick-prev {
    position: absolute;
    right: unset !important;
    left: 2rem !important;
    top: -1rem;
  }
}

@media (width < 1400px) {
  .slider-area .slick-arrow.slick-next {
    right: 7%;
  }
  button.slick-arrow.slick-prev {
    right: 13% !important;
    left: unset;
  }
}


.slick-dots li button {
  font-size: 0;
  line-height: 0;
  display: block;
  width: 20px;
  height: 20px;
  padding: 5px;
  cursor: pointer;
  color: transparent;
  border: 0;
  outline: none;
  background: #9a9a9a6b !important;
  scale: 0.75 !important;
}

.slick-dots li.slick-active button{
  background-color: var(--tg-primary-color) !important;
  scale: 0.9 !important;
}

.slick-dots button:before{
  display: none !important;
}