.tradinggraph {
  max-height: 480px;
}
.btn_bulky {
  width: 100%;
  min-height: 50px;
}

.br4 {
  border-radius: 4px;
}

.quant_changer {
  max-width: 100%;
  /* background-color: #373e51; */
  border: 1px solid #191f2d;
}
.lable_n_input {
  padding: 4px;
  border: 1px solid #191f2d;
  border-radius: 4px 0 0 4px;
  background-color: #373e51;
}

.quant_changer .lable_n_input input {
  background: #373e51;
  border: none;
  outline: none;
}
.quant_changer .lable_n_input input:focus {
  background: rgb(29 36 52);
}

.lable_n_input:has(.change_input:focus) {
  border: 1px solid rgba(255, 255, 255, 0.5);
  background-color: rgb(29 36 52);
}
.quant_changer .lable_n_input {
  /* max-width: 80%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.quant_changer .change_input {
  -moz-appearance: none;
  -webkit-appearance: none;
  margin: 0;
}

.quant_changer .change_input::-webkit-outer-spin-button,
.quant_changer .change_input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.quant_changer_btn {
  background: rgb(54 60 79);
  color: rgba(255, 255, 255, 0.8);
  padding: 2px;
  width: 100%;
  height: 50%;
  border: 1px solid #191f2d;
  border-radius: 0 !important;
}
.quant_changer_btn:hover {
  color: #fff;
  background: rgb(55 62 81 / 70%);
}
.quant_changer_btn.inc {
  border-top-right-radius: 4px;
}
.quant_changer_btn.dec {
  border-bottom-right-radius: 4px;
}

.tp_n_sl {
  display: flex;
  flex-direction: column;
  gap: 1px;
}

.tp_n_sl > .bgelem:hover {
  background: rgb(72 79 96);
}

.bg_red {
  background-color: rgb(219 73 49);
}
.btn.bg_red:hover {
  background-color: rgba(219, 72, 49, 0.8);
}

.popup {
  position: absolute;
  left: -16.9rem;
  top: 12rem;
  background: rgb(28 32 48);
}
.popupheader {
  background-color: rgb(38 44 59);
}

.btn-nm {
  padding: 4px 30px;
}
.cancel_btn {
  border: 1px solid rgba(255, 255, 255, 0.3);
}
.cancel_btn:hover {
  background-color: rgb(55 62 81);
  color: white;
}
.apply_btn {
  background-color: rgb(55 62 81);
  border: 1px solid rgb(55 62 81);
  font-weight: 800;
}
.apply_btn:hover {
  color: white;
  background-color: rgb(55 62 81);
}
.scale_in {
  animation-name: scalein;
  animation-duration: 0.2s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 1;
}

@keyframes scalein {
  0% {
    scale: 0.5;
    opacity: 0;
  }
  100% {
    scale: 1;
    opacity: 1;
  }
}

/* #margin_accord{
    max-height: 100px;
    height: 100%;
} */
.accordion-header {
}
.accordion-item {
  background: rgb(39 49 69);
  border: none;
  color: white;
  box-shadow: unset;
  border-radius: 0 !important;
}

button.accordion-button {
  padding: 10px 15px;
  background-color: #373e51;
  color: white;
  box-shadow: unset;
  max-height: 150px;
  height: 100%;
}
.accordion-button:not(.collapsed) {
  color: white;
  background-color: #373e51;
  box-shadow: unset;
}
.accordion-button:focus {
  box-shadow: unset !important;
}

.leftside_bar {
  max-width: 95px;
  /* min-height: calc(100vh - 80px); */
  min-height: 100%;
}

@media screen and (max-width: 576px) {
  /* .leftside_bar {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100vw;
        z-index: 9;
        padding-inline: 0px;
        padding-block: 2px !important;
    } */
  .rightside_bar {
    padding-bottom: 10px !important;
  }
  .pnl_btns {
    position: fixed;
    z-index: 99;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 10px;
    background: #191f2d;
    /* border-top: 1px solid #ffffff30; */
    box-shadow: 0px 0px 18px 1px rgb(255 255 255 / 20%);
  }
  div#margin_accord {
    margin-bottom: 84px;
  }
}
@media screen and (min-width: 576px) {
  .leftside_bar {
    max-width: 95px;
    min-height: calc(100vh - 80px);
  }
  .trading_graph {
    max-width: calc(100% - 95px);
  }
  .btn_bulky {
    width: 100%;
    min-height: 100px;
  }
  .tradinggraph {
    height: 100%;
  }
}

@media screen and (min-width: 1300px) and (max-width: 1500px) {
  .trading_graph_wrapper {
    width: calc(100% - 135px);
  }
  .rightsidebar_wrapper {
    width: 135px;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate(-50%, -100%);
  }
  to {
    opacity: 1;
    transform: translate(-50%, -120%);
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

.result-card {
  animation: fadeInUp 0.3s ease-out;
}

.result-card.removing {
  animation: fadeOut 0.3s ease-in forwards;
}

.trading-graph-container {
  position: relative;
  width: 100%;
  height: 100%;
}
